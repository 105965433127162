import "./App.css";
import Router from "./routes/Router"

function App() {
  return (
    <div>
      <Router />

    </div>
  );
}

export default App;
