import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseQuery } from './baseQuery';

export const cloudClinicApi = createApi({
  reducerPath: 'cloudClinicApi',
  baseQuery: BaseQuery,
  endpoints: (builder) => ({
    getCloudClinicApi: builder.query({
      query: (name) => name,
    }),
    createCloudClinicApi: builder.mutation({
      query: (requestData) => ({
        url: requestData.path, 
        method: 'POST',
        body: requestData.datas,
      }),
    }),
    editCloudClinicApi: builder.mutation({
      query: ({ id, data }) => ({
        url: `/baseUrl/${id}`, 
        method: 'PUT',
        body: data, 
      }),
    }),
    deleteCloudClinicApi: builder.mutation({
      query: (id) => ({
        url: `/baseUrl/${id}`, 
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetCloudClinicApiQuery,
  useCreateCloudClinicApiMutation,
  useEditCloudClinicApiMutation,
  useDeleteCloudClinicApiMutation,
} = cloudClinicApi;
