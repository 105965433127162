import React, { useMemo } from 'react';

const OTP_DIGIT = /^\d$/; // Allows only single digits

const focusToNextInput = (target) => {
  const nextElementSibling = target.nextElementSibling;
  if (nextElementSibling) {
    nextElementSibling.focus();
  }
};

const focusToPrevInput = (target) => {
  const previousElementSibling = target.previousElementSibling;
  if (previousElementSibling) {
    previousElementSibling.focus();
  }
};

const OtpInput = ({ value = '', valueLength, onChange }) => {
  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items = [];
    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i] || '';
      items.push(OTP_DIGIT.test(char) ? char : '');
    }
    return items;
  }, [value, valueLength]);

  const inputOnChange = (e, idx) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = OTP_DIGIT.test(targetValue);

    // Adjust the value based on input
    let newValue = value.split('');
    newValue[idx] = targetValue;

    // Update only if the input is a digit or if it's a backspace action
    if (isTargetValueDigit || targetValue === '') {
      newValue = newValue.join('');
      onChange(newValue);
      if (isTargetValueDigit && targetValue !== '') {
        focusToNextInput(target);
      }
    }
  };

  const inputOnKeyDown = (e) => {
    const { key, target } = e;
    if (key === 'Backspace' && !target.value) {
      focusToPrevInput(target);
    } else if (key === 'ArrowRight' || key === 'ArrowDown') {
      focusToNextInput(target);
    } else if (key === 'ArrowLeft' || key === 'ArrowUp') {
      focusToPrevInput(target);
    }
  };

  const inputOnFocus = (e) => {
    const target = e.target;
    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div className="w-full text-center">
      {valueItems.map((digit, idx) => (
        <input
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={1}
          className="otp-box h-[72px] w-[56px] outline-primary outline-2 mr-3 border border-solid-primary border-primary rounded-[5px] text-center text-3xl font-bold"
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
          tabIndex={0}
        />
      ))}
    </div>
  );
};

export default OtpInput;
