const Table = ({ headers, data }) => {
  return (
    <table className="w-full">
      <thead className="w-full">
        <tr className="w-full">
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody className="w-full">
        {data?.map((datum,index) => (
          <tr key={datum?.id || index} className="w-full">
            {headers.map((header, index) => (
              <td key={index}>
                <span>{datum[header.toLowerCase()]}</span>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
