export const availableBalance = 100000;
export const bookBalance = 10000;

export const hideBalance = ({ balance, hide }) => {
  const hidden = balance
    .split("")
    .map(() => "*")
    .join("");
  return hide ? hidden : balance;
};

function getCurrentDate() {
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", options);

  return `Today, ${formattedDate}`;
}

export const currentDate = getCurrentDate();

export const transactions = [
  {
    orderNo: "CCL101",
    sender: "John Doe",
    amount: 10000,
    time: "20:37",
    refCode: "",
  },
  {
    orderNo: "CCL101",
    sender: "John Doe",
    amount: 10000,
    time: "20:37",
    refCode: "",
  },
  {
    orderNo: "",
    sender: "John Doe",
    amount: 10000,
    time: "20:37",
    refCode: "1011209584894",
  },
];
