import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "../components/Button";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import OtpInput from "../components/OtpInput";
import { usePostCreateApiHook } from "../api/hooks";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";

const TariffEmail = () => {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { post } = usePostCreateApiHook();
  const navigate = useNavigate();

  const email = useSelector((state) => state?.user?.user.partnerEmail);
  const id = useSelector((state) => state?.user?.user.id);
  const type = useSelector((state) => state?.user?.user.facilityType);

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  const submitOtp = async () => {
    const payload = {
      otp: otp,
      email: email,
    };
    setIsLoading(true);

    try {
      const response = await post(
        "/sponsor/ValidateNonPartnerOtp",
        payload
      );
      if (response.data.isSuccessful) {
        toast.success("OTP validated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate("/facility/add-facility");
        }, 500);
      } else {
        throw new Error(response.data.message || "OTP validation failed");
      }
    } catch (error) {
      toast.error(error.message || "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { backgroundColor: "red" },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resendOtp = async () => {
    const payload = {
      email: email,
      id: id,
      type: type
    };
    try {
      const response = await post(
        "/facilityauth/api/FacilityAuth/RequestOtp",
        payload
      );
      if (response.data.isSuccessful) {
        toast.info(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.error("Failed to resend OTP", response.error);
      }
    } catch (error) {
      console.error("Error resending OTP", error);
    }
  };

  return (
    <div className="relative min-h-screen bg-cover bg-picture shrink-0 flex flex-col">
      <NavBar />
      <div className="flex md:justify-end h-auto md:px-10 py-14 mt-20">
        <div className="flex flex-col aspect-[711/574] bg-secondaryLight rounded-xl md:w-1/2 w-full md:px-20 md:py-10 px-4 py-4 items-center justify-between">
          <h4 className="text-3xl text-primary text-center font-bold">
            Email Verification
          </h4>
          <p className="text-grey text-center my-6">
            We’ve sent an email containing an OTP code to your email address,
            please enter it below
          </p>
          <div className="flex items-center">
            <OtpInput value={otp} onChange={handleOtpChange} valueLength={4} />
          </div>
          <div className="flex justify-center items-center cursor-pointer">
            <p className="text-sm">Didn't receive any code?</p>
            <span className="text-primary text-base ml-1" onClick={resendOtp}>
              Request Again
            </span>
          </div>

          <div className="flex justify-center mt-8 w-full">
            <Button
              type="submit"
              btnText={isLoading ? <Spinner /> : "Login"}
              onClick={submitOtp}
              className={`text-white bg-primary w-full border-0 px-5 py-2 rounded-xl ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
      <Footer showFooterlinks={false} />
    </div>
  );
}

export default TariffEmail;
