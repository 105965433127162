import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

import { userReducer } from './userSlice';
import { cloudClinicApi } from '../api/example';
import { priceListReducer } from './priceListSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'], // You can choose which reducers to persist here
};

const rootReducer = combineReducers({
  [cloudClinicApi.reducerPath]: cloudClinicApi.reducer,
  user: userReducer,
  priceList: priceListReducer, 
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).concat(cloudClinicApi.middleware),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
