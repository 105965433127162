import Button from "../../../components/Button";

export const tabs = ["Invoice", "Orders"];

export const naira = "₦";

export const returnTableHeaders = ({ currentView }) => {
  return [
    "Name",
    `${currentView === "Invoice" ? "Invoice" : "Order"} ID`,
    "Sex",
    "Date",
    "Action",
  ];
};

export const orderStatus = ["Pending", "Delivered"];

export const returnTableData = ({ onClickView, currentView, arr }) => {
  const currentDate = new Date().toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  console.log(transformDateFormat(currentDate));

  return (
    arr?.map((item, index) => ({
      name:
        item?.patientName || `${item?.user?.firstName} ${item?.user?.lastName}`,
      [`${currentView === "Invoice" ? "invoice" : "order"} id`]: item?.[
        currentView === "Invoice" ? "userId" : "orderId"
      ]?.substring(0, 8),
      sex: item?.patientSex || item?.user?.gender,
      date: transformDate(item?.updatedAt || item?.date),
      action: onClickView ? (
        <Button
          btnText="View"
          onClick={() => onClickView(index)}
          className="text-primary font-bold text-base"
        />
      ) : null,
    })) ||
    Array.from({ length: 6 }, (_, index) => ({
      name: "John Doe",
      [`${currentView === "Invoice" ? "invoice" : "order"} id`]: "2346567",
      sex: "Male",
      date: transformDateFormat(currentDate),
      action: onClickView ? (
        <Button
          btnText="View"
          onClick={() => onClickView(index)}
          className="text-primary font-bold text-base"
        />
      ) : null,
    }))
  );
};
export function transformDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  return `${day} ${month}, ${year}`;
}

export function transformDateFormat(dateString) {
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const time = `${hours}:${minutes}`;

  return `${formattedDate} ${time}`;
}
export const returnInfo = ({ name, sex, age, doctorName, lmdId }) => {
  return [
    {
      title: "Patient Info",
      content: [
        { label: "Name", value: name },
        { label: "Sex", value: sex },
        { label: "Age", value: age },
      ],
    },
    {
      title: "Doctor’s Info",
      content: [
        { label: "Name", value: doctorName },
        { label: "LMD ID", value: lmdId },
        { label: "Age", value: age },
      ],
    },
  ];
};
export const transactions = ({ date, serviceType, name, paymentMethod }) => {
  return [
    {
      content: [
        { label: "Date Created", value: date },
        { label: "Service Type", value: serviceType },
      ],
    },
    {
      content: [
        { label: "Name", value: name },
        { label: "Payment Method", value: paymentMethod },
      ],
    },
  ];
};
export const invoiceDetailsTableHeaders = [
  "",
  "Name of investigation",
  "Service",
  "Price",
  " ",
];

const investigationNames = [
  "Albumin - (Serum)",
  "Bilirubin (direct) - (serum)",
  "Blood Differential test",
];

const obj = {
  "": "",
  "name of investigation": "",
  service: "",
  price: "",
  " ": "",
};

export const restructured = ({ arr }) =>
  arr?.map((item, index) => ({
    "": index + 1,
    "name of investigation": item?.productName || item?.serviceName,
    service: "Laboratory",
    price: item?.productPrice || item?.price,
  })) ||
  investigationNames.map((name, index) => ({
    ...obj,
    "": index + 1,
    "name of investigation": name,
    service: "Laboratory",
    price: 200,
  }));

export const noteFromDoctor =
  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt";

export const addNewFormData = [
  {
    label: "Investigation Name *",
    placeholder: "Enter investigation name",
    type: "text",
  },
  { label: "Price *", placeholder: "Enter Price", type: "number" },
];

export const invoicePreviewTableHeaders = [
  "Investigation Name",
  "Quantity",
  "Price",
  "Actions",
];

export const returninvoicePreviewTableData = ({ arr = [], onClickAction }) => {
  const actionTexts = ["Delete", "Edit"];
  return arr.map((item, index) => ({
    "investigation name": item.serviceName,
    quantity: item.quantity || 1, // Assuming quantity is always 1 if not provided
    price: item.price,
    actions: (
      <div className="flex items-center gap-4" key={index}>
        {actionTexts.map((text) => (
          <Button
            key={text}
            btnText={text}
            onClick={() => onClickAction({ text, index })}
            className={`${
              text === "Delete" ? "text-red-500" : "text-primary"
            } font-semibold text-base`}
          />
        ))}
      </div>
    ),
  }));
};
