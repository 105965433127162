import React from "react";

const Input = ({
  type,
  id,
  name,
  value,
  onChange,
  register,
  required,
  label,
  placeholder,
  max,
  min,
  disabled,
  boldLabel,
  options,
  onFocus,
  onBlur,
}) => {
  const isValidEmail = (email) => {
    return /^[\w.%+-]+@[\w-]+\.[\w]{2,}$/i.test(email);
  };

  const isValidUniqueId = (uniqueId) => {
    return /^\w+$/.test(uniqueId);
  };

  const renderInput = () => {
    switch (type) {
      case "email":
      case "password":
      case "text":
      case "partnerId":
      case "search":
      case "number":
      case "date":
        return (
          <div className="relative">
            <input
              type={type}
              id={id}
              name={name}
              value={value}
              max={max}
              min={min}
              disabled={disabled}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              {...(register &&
                register(name, {
                  disabled: disabled,
                  required: {
                    value: required,
                    message: `${label} is required`,
                  },
                  validate: (fieldValue) => {
                    if (type === "email") {
                      return (
                        isValidEmail(fieldValue) ||
                        "Enter a valid email address"
                      );
                    } else if (type === "partnerId") {
                      return (
                        isValidUniqueId(fieldValue) ||
                        "Enter a valid partner ID (alphanumeric characters only)"
                      );
                    }
                  },
                }))}
              placeholder={placeholder}
              className="block w-full py-1 text-slate-800 px-2 bg-white focus:outline-none"
            />
          </div>
        );
      case "select":
        return (
          <select
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            {...(register &&
              register(name, {
                required: {
                  value: required,
                  message: `${label} is required`,
                },
              }))}
            className="block w-full py-1 text-slate-800 px-2 bg-white focus:outline-none"
          >
            <option value="">Select</option>
            {options?.map((option, index) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      case "radio":
        return (
          <div className="flex items-center">
            {options?.map((option, index) => (
              <label key={index} className="inline-flex items-center mr-4">
                <input
                  type={type}
                  id={`${id}-${index}`}
                  name={name}
                  value={option.value}
                  checked={value === option.value}
                  onChange={onChange}
                  className="form-radio h-5 w-5 text-[#008000]"
                />
                <span className="ml-2 text-gray-700">{option.label}</span>
              </label>
            ))}
          </div>
        );
      case "file":
        return (
          <input
            type="file"
            id={id}
            placeholder={placeholder}
            accept=".pdf,.doc,.docx,.xls,.xlsx"
            onChange={onChange}
            className="block w-full py-1 text-slate-800 px-2 bg-white focus:outline-none"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={`w-full border-[0.1px] bg-white border-[#ABABAB] rounded-md py-1 px-4`}
    >
      {label && (
        <label
          htmlFor={id}
          className={`  ${
            boldLabel ? "font-semibold" : "font-normal"
          } text-[#444448]`}
        >
          {label}
        </label>
      )}
      {renderInput()}
    </div>
  );
};

export default Input;
