import Button from "../../../components/Button";
export const proceduralTableHeaders = [
  "Procedural Name",
  "Category",
  "Price",
  "Actions",
];

const btnTexts = ["Delete", "Edit"];

export const returnProceduralTableData = (clickAction) => {
  return [
    {
      id: 0,
      "procedural name": "Eye Service",
      category: "Eye Check-Up Hospital service",
      price: "₦ 200.00",
      actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 0)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 1,
      "procedural name": "Eye Service",
      category: "Eye Check-Up Hospital  service",
      price: "₦ 200.00",
      actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 1)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 2,
      "procedural name": "Optical Service",
      category: "Eye Check-Up Hospital service",
      price: "₦ 200.00",
      actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 2)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 3,
      "procedural name": "Eye Service",
      category: "Eye Check-Up Hospital service",
      price: "₦ 200.00",
      actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 3)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 4,
      "procedural name": "Eye Service",
      category: "Eye Check-Up Hospital service",
      price: "₦ 200.00",
      actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 4)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
  ];
};

export const editFormData = [
  {
    type: "text",
    label: "Procedural Name *",
    name: "proceduralName",
    value: "",
  },
  { type: "text", label: "Category", name: "category", value: "" },
  { type: "number", label: "Price *", name: "price", value: "" },
];

export const unresolvedProceduralTableHeaders = [
  "Procedural Name",
  "Category",
  "Action",
];

export const returnUnresolvedProceduralTableData = (clickAction) => {
  const obj = { id: 0, "procedural name": "", category: "", action: "" };
  return returnProceduralTableData().map((item) => ({
    ...obj,
    "procedural name": item["procedural name"],
    id: item.id,
    category: item.category,
    action: <Button key={item.id} onClick={() => clickAction(item.id)} btnText='View' className='bg-primary rounded-lg text-secondaryLight py-3 px-8' />
  }));
};
const buttonTexts = ["View", "Edit", "Delete"];

export const pharmarcyreturnPriceListTableData = (clickAction) => {
  return [
    {
      id: 0,
      "Medication Name": "Infusion Set",
      "Medication Mass": "NHIS",
      Price: "₦ 200.00",
      Actions: (
        <div className="flex items-center gap-8">
          {buttonTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"} text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 0)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 1,
      "Medication Name": "Infusion Set",
      "Medication Mass": "NHIS",
      Price: "₦ 200.00",
      Actions: (
        <div className="flex items-center gap-8">
          {buttonTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"} text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 1)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 2,
      "Medication Name": "Infusion Set",
      "Medication Mass": "NHIS",
      Price: "₦ 200.00",
      Actions: (
        <div className="flex items-center gap-8">
          {buttonTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"} text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 2)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 3,
      "Medication Name": "Infusion Set",
      "Medication Mass": "NHIS",
      Price: "₦ 200.00",
      Actions: (
        <div className="flex items-center gap-8">
          {buttonTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"} text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 3)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 4,
      "Medication Name": "Infusion Set",
      "Medication Mass": "NHIS",
      Price: "₦ 200.00",
      Actions: (
        <div className="flex items-center gap-8">
          {buttonTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"} text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 4)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },

  ];
};
