import Table from "../../../components/Table";
import { BackBtn, PageTitle, PriceListTableWrapper } from "../Home/Home.styled";
import { ConflictsDisplay, InfoIcon, InfoText } from "../Home/Home.styled";
import { LuRefreshCcw } from "react-icons/lu";
import { returnUnresolvedProceduralTableData, unresolvedProceduralTableHeaders } from "./data";

const UnresolvedProceduralList = ({ goBack, conflictCount, clickAction }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <BackBtn
          onClick={goBack}
          className="cursor-pointer text-primary w-6 h-6"
        />
        <PageTitle className="font-bold">
          Unresolved Procedural Price List
        </PageTitle>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex w-full justify-between items-center">
          {conflictCount > 0 && (
            <ConflictsDisplay>
              <InfoIcon />
              <InfoText $conflictsCount={conflictCount} />
            </ConflictsDisplay>
          )}
          <LuRefreshCcw className="w-6 h-6 text-primary cursor-pointer" />
        </div>
        <PriceListTableWrapper>
          <Table
            headers={unresolvedProceduralTableHeaders}
            data={returnUnresolvedProceduralTableData(clickAction)}
          />
        </PriceListTableWrapper>
      </div>
    </div>
  );
};

export default UnresolvedProceduralList;
