import { BackBtn, InfoIcon, PriceListTableWrapper } from "../Home/Home.styled";
import { useContext, useState } from "react";
import { OrdersContext } from "./context";
import Table from "../../../components/Table";
import {
  invoicePreviewTableHeaders,
  returninvoicePreviewTableData,
} from "./data";
import Button from "../../../components/Button";
import SuccessModal from "../../../components/modals/SuccessModal";
import InvoiceEditModal from "../../../components/modals/InvoiceEditModal";

const InvoicePreview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const { values, setValues } = useContext(OrdersContext);
  const { createInvoice, invoiceCreated } = values;

  const handleGoBack = () => {
    setValues((prevValues) => ({
      ...prevValues,
      createInvoice: false,
      invoiceCreated: false,
    }));
  };

  const handlePostInvoice = () => {
    setValues((prevValues) => ({ ...prevValues, invoiceCreated: true }));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClickAction = ({ text, index }) => {
    if (text === "Edit") {
      const currentDetail = returninvoicePreviewTableData({ onClickAction: handleClickAction })[index];
      setFormData([
        { label: "Medication Name", type: "text", name: "medicationName", value: currentDetail["medication name"] },
        { label: "Quantity", type: "number", name: "quantity", value: currentDetail.quantity },
        { label: "Price", type: "text", name: "price", value: currentDetail.price },
      ]);
      setIsModalOpen(true);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) =>
      prevFormData.map((data) =>
        data.name === name ? { ...data, value } : data
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data: ", formData);
    handleCloseModal();
  };

  return (
    <>
      {createInvoice && (
        <div className="flex flex-col gap-5 pb-10">
          <span className="flex items-center gap-4">
            <BackBtn
              className="text-primary w-6 h-6 cursor-pointer"
              onClick={handleGoBack}
            />
            Invoice Preview
          </span>
          <p className="flex items-center justify-center bg-lightOrange gap-5 py-3 font-medium text-base text-black">
            <InfoIcon />
            Any changes of prices here will reflect on master list
          </p>
          <PriceListTableWrapper>
            <Table
              headers={invoicePreviewTableHeaders}
              data={returninvoicePreviewTableData({
                onClickAction: handleClickAction,
              })}
            />
          </PriceListTableWrapper>
          <Button
            btnText="Post Invoice"
            className="ml-auto bg-primary rounded-button py-4 px-24 text-secondaryLight"
            onClick={handlePostInvoice}
          />
          <SuccessModal
            isOpen={invoiceCreated}
            closeModal={handleGoBack}
          ></SuccessModal>
          <InvoiceEditModal
            formData={formData}
            onChange={handleFormChange}
            onSubmit={handleSubmit}
            isOpen={isModalOpen}
            closeModal={handleCloseModal}
          />
        </div>
      )}
    </>
  );
};

export default InvoicePreview;
