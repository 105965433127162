import { IoClose } from "react-icons/io5";

const Modal = ({ isOpen, closeModal, children, className }) => {
  const appBody = document.querySelector("body");
  appBody.style.overflow = isOpen ? "hidden" : "auto";
  const handleClose = (e) => {
    if (e.target.id === "wrapper") closeModal();
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed top-0 left-0 h-screen w-screen flex items-center justify-center z-50 bg-black bg-opacity-50"
      onClick={handleClose}
    >
      <div className={`${className} fixed z-10 bg-secondaryLight rounded-lg p-4 md:p-8`}>
        <button
          className="text-black md:text-2xl font-semibold text-lg absolute top-0 right-0 m-4"
          onClick={closeModal}
        >
          <IoClose />
        </button>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
