import { Link, useLocation } from "react-router-dom";
import { ReactComponent as OrderIcon } from "../assets/svgs/Icon.svg";
import { ReactComponent as UploadIcon } from "../assets/svgs/upload-01.svg";
import { ReactComponent as TarrifIcon } from "../assets/svgs/list.svg";
import { ReactComponent as WalletIcon } from "../assets/svgs/wallet-01.svg";
import { ReactComponent as HomeIcon } from "../assets/svgs/home-01.svg";
import { ReactComponent as LogoutIcon } from "../assets/svgs/LogOut.svg";
import { ReactComponent as SwitchIcon } from "../assets/svgs/switch_icon.svg";
import { ReactComponent as AddIcon } from "../assets/svgs/addCircle.svg";
import { ReactComponent as BillsIcon } from "../assets/svgs/bills.svg";
import styled from "styled-components";
import { useEffect, useState } from "react";

const sideBarLinks = [
  { icon: <HomeIcon />, label: "Home", path: "" },
  { icon: <OrderIcon />, label: "Orders", path: "orders" },
  { icon: <UploadIcon />, label: "Pricelist", path: "pricelist" },
  { icon: <TarrifIcon />, label: "Tariff", path: "tarrif" },
  { icon: <WalletIcon />, label: "Wallet", path: "wallet" },
];

const bottomLinks = [
  {
    icon: <SwitchIcon />,
    label: "Switch Facility",
    path: "/facility/activation",
  },
  { icon: <LogoutIcon />, label: "Logout", path: "#" },
];

const SideBar = ({ showSpecialLinks }) => {
  const location = useLocation();
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const currentPath = location.pathname.split("/")[1];

  return (
    <nav className="bg-secondaryLight w-fit md:w-80 p-6 flex flex-col gap-64 h-full overflow-x-hidden overflow-y-auto">
      <ul className="flex flex-col gap-14 w-fit">
        {sideBarLinks.map((item, index) => {
          if (item.path === "pricelist" && showSpecialLinks) {
            return (
              <div  key={item.label}>
                <StyledLink
                  className="flex text-left w-fit hover:text-primary"
                  to={item.path}
                  $active={
                    !currentPath ? index === 0 : currentPath === item.path
                  }
                >
                  <li className="flex items-center gap-2.5 whitespace-nowrap">
                    {item.icon}
                    {isLargeScreen ? item.label : ""}
                  </li>
                </StyledLink>
                <div className="flex flex-col mt-12 gap-14">
                  <StyledLink
                    className="flex text-left w-fit hover:text-primary"
                    to="patient"
                    $active={currentPath === "patient"}
                  >
                    <li className="flex items-center gap-2.5 whitespace-nowrap">
                      <AddIcon />
                      {isLargeScreen ? "Patient" : ""}
                    </li>
                  </StyledLink>
                  <StyledLink
                    className="flex text-left w-fit hover:text-primary"
                    to="bills"
                    $active={currentPath === "bills"}
                  >
                    <li className="flex items-center gap-2.5 whitespace-nowrap">
                      <BillsIcon />
                      {isLargeScreen ? "Bills" : ""}
                    </li>
                  </StyledLink>
                </div>
              </div>
            );
          }

          return (
            <StyledLink
              key={item.label}
              className="flex text-left w-fit hover:text-primary"
              to={item.path}
              $active={
                !currentPath ? index === 0 : currentPath === item.path
              }
            >
              <li className="flex items-center gap-2.5 whitespace-nowrap">
                {item.icon}
                {isLargeScreen ? item.label : ""}
              </li>
            </StyledLink>
          );
        })}
      </ul>
      <ul className="flex flex-col gap-12 w-fit">
        {bottomLinks.map((link) => (
          <StyledLink
            key={link.label}
            to={link.path}
            className={`flex text-left w-fit ${
              link.label === "Logout" ? "" : "hover:text-primary"
            }`}
            $isLogout={link.label === "Logout"}
          >
            <li
              className={`${
                link.label === "Logout" ? "text-red-500" : ""
              } flex items-center gap-2.5 whitespace-nowrap`}
            >
              {link.icon}
              {isLargeScreen ? link.label : ""}
            </li>
          </StyledLink>
        ))}
      </ul>
    </nav>
  );
};

export default SideBar;

const StyledLink = styled(Link)`
  color: ${({ $active }) => ($active ? "#2cbcef" : "")};

  svg path {
    stroke: ${({ $active }) => ($active ? "#2cbcef" : "")};
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  &:hover {
    svg path {
      stroke: #${({ $isLogout }) => ($isLogout ? "" : "2cbcef")};
      color: #${({ $isLogout }) => ($isLogout ? "" : "2cbcef")};
    }
  }
`;
