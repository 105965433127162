import React, { useState, useEffect } from 'react';
import axiosInstance from '../../api/constants';
import Modal from './Modal';
import Input from "../Input";
import Button from '../Button';
import { SearchIcon } from "../../components/TarrifBreakdown/TarrifBreakdown.styled";
import { ReactComponent as RedCloseIcon } from "../../assets/svgs/RedCloseIcon.svg";
import { usePostCreateApiHook } from '../../api/hooks';
import { useSelector } from 'react-redux';

const AddInvestigationModal = ({
  isOpen,
  closeModal,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [investigations, setInvestigations] = useState([]);
  const [filteredInvestigations, setFilteredInvestigations] = useState([]);
  const [selectedInvestigation, setSelectedInvestigation] = useState(null);
  const [price, setPrice] = useState('');
  const { post } = usePostCreateApiHook();
  const labId = useSelector((state) => state?.user?.user?.id);
  const invMasterId = useSelector((state) => state?.user?.user?.masterList);

  const resetForm = () => {
    setSearchQuery('');
    setSelectedInvestigation(null);
    setPrice('');
    setFilteredInvestigations([]);
  };

  const handleCloseModal = () => {
    resetForm();
    closeModal();
  };

  useEffect(() => {
    const fetchInvestigations = async () => {
      try {
        const response = await axiosInstance.get('/lab/api/Lab/MasterList');
        if (response.data && Array.isArray(response.data.data)) {
          setInvestigations(response.data.data);
        } else {
          console.error('Fetched data is not an array:', response.data);
        }
      } catch (error) {
        console.error('Error fetching investigations:', error);
      }
    };

    fetchInvestigations();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const filtered = investigations.filter((inv) =>
        inv.investigationName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredInvestigations(filtered);
    } else {
      setFilteredInvestigations([]);
    }
  }, [searchQuery, investigations]);

  const handleSelectInvestigation = (investigation) => {
    setSelectedInvestigation(investigation);
    setSearchQuery(investigation.investigationName);
    setFilteredInvestigations([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedInvestigation && price) {
      const payload = {
        labId: labId,
        invMasterId: selectedInvestigation.id,
        price,
      };
      try {
        const response = await post('/lab/api/Lab/AddInvestigation', payload);
        console.log('Investigation submitted:', response.data);
        resetForm();
        closeModal();
      } catch (error) {
        console.error('Error submitting investigation:', error);
      }
    } else {
      console.error('Please select an investigation and enter a price');
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} closeModal={handleCloseModal}>
        <div className="w-[40vw] flex flex-col gap-12 mt-6">
          <h1 className="font-bold text-deepBlue text-xl">Add Investigation</h1>
          <div>
            <form className="flex flex-col w-full pb-2 gap-5" onSubmit={handleSubmit}>
              <div className="relative flex flex-col border-b-2 border-b-primary">
                <Input
                  type="search"
                  label="CCL Investigation name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <SearchIcon $toRight color="#444448" />
                {filteredInvestigations.length > 0 && (
                  <ul className="absolute bg-white border border-gray-300 w-full mt-2 z-10 max-h-60 overflow-auto">
                    {filteredInvestigations.map((inv) => (
                      <li
                        key={inv.id}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleSelectInvestigation(inv)}
                      >
                        {inv.investigationName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="relative flex flex-col border-b-2 border-b-primary">
                <Input
                  type="text"
                  label="Investigation Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>

              <div className="w-full flex flex-col gap-5">
                <div className="w-full flex items-center justify-between">
                  <span>Selected Investigation</span>
                  <RedCloseIcon
                    onClick={handleCloseModal}
                    className="cursor-pointer"
                  />
                </div>
                {selectedInvestigation && (
                  <div className="w-full p-5 flex flex-col gap-7 rounded-[0.625rem] border border-gray-200">
                    <p>
                      Category: <span>{selectedInvestigation.category}</span>
                    </p>
                    <p>
                      Investigation Name: <span>{selectedInvestigation.investigationName}</span>
                    </p>
                  </div>
                )}
                <div className="flex items-center ml-auto">
                  <Button
                    btnText="Submit"
                    className="bg-primary text-base font-medium text-white py-2 px-10 rounded-lg"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddInvestigationModal;
