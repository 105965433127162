import { useEffect, useState } from "react";
import {
  ConflictsDisplay,
  HomeTop,
  HomeWrapper,
  InfoIcon,
  InfoText,
  PageTitle,
  PriceListTableWrapper,
  PriceListWrapper,
  SearchForm,
  SearchInput,
} from "../Home/Home.styled";
import { SearchIcon } from "../../../components/TarrifBreakdown/TarrifBreakdown.styled";
import {
  priceListTableHeaders,
  emailAddress,
  editFormData,
} from "../Home/data";
import { ReactComponent as UploadIcon } from "../../../assets/svgs/upload-01.svg";

import Table from "../../../components/Table";
import Modal from "../../../components/modals/Modal";
import EditModal from "../../../components/modals/EditModal";
import SuccessModal from "../../../components/modals/SuccessModal";
import Button from "../../../components/Button";
import EmailAddressChange from "../Home/EmailAddressChange";
import AddInvestigationModal from "../../../components/modals/AddInvestigationModal";
import UploadPricelist from "./UploadPricelist";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosInstance from "../../../api/constants";
import { toast } from "react-toastify";
import ViewUnresolved from "../Home/ViewUnresolved";
import UnresolvedPriceList from "../Home/UnresolvedPriceList";

const Home = ({ isOpen, closeModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formDetails, setFormDetails] = useState(editFormData);
  const [showConflicts, setShowConflicts] = useState(false);
  const [emailSuccessful, setEmailSuccessful] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [itemInView, setItemInview] = useState(null);
  const [showAddInvestigationModal, setShowAddInvestigationModal] = useState(false);
  const [showUploadPage, setShowUploadPage] = useState(false);
  const [priceListData, setPriceListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [conflictCount, setConflictCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [unresolvedData, setUnresolvedData] = useState([]);
  const [page, setPage] = useState({ pageNumber: 1, pageSize: 10 });

  const labID = useSelector((state) => state.user?.user?.id);

  const navigation = useNavigate();
  const fetchData = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await axiosInstance.get(
        `/lab/api/Lab/Investigation?pharmId=${labID}`
      );

      const sortedData = response.data.data.sort((a, b) =>
        a.investigationName.localeCompare(b.investigationName)
      );
      setPriceListData(sortedData);
    } catch (error) {
      setIsError(true);
      console.error("Error fetching price list data:", error);
    }
    setIsLoading(false);
  };


  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handleClickAction = (text, index) => {
    setSelectedId(index);
    console.log(index);
    const currentDetail = index;
    if (text === "Edit") {
      setIsModalOpen(true);
      console.log(index);
      setFormDetails((prevFormDetails) =>
        prevFormDetails.map((detail) =>
          detail.name === "labId"
            ? { ...detail, value: currentDetail.labServiceId }
            : detail.name === "investigationName"
            ? { ...detail, value: currentDetail.investigationName }
            : detail.name === "category"
            ? { ...detail, value: currentDetail.category }
            : detail.name === "price"
            ? { ...detail, value: currentDetail.price }
            : detail
        )
      );
    } else if (text === "Delete") {
      // handleDelete(currentDetail.labServiceId);
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const updatedDetail = formDetails.reduce((acc, detail) => {
      acc[detail.name] = detail.value;
      return acc;
    }, {});
    const accessToken = localStorage.getItem("accessToken");

    console.log(selectedId.labServiceId);
    try {
      await axiosInstance.put(
        `/lab/api/Lab/UpdatePrice`,
        {
          labId: selectedId.labServiceId,
          price: Number(updatedDetail.price),
        },
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Price changed successfully");
      fetchData();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Failed to update price");
    }
  };

  const handleDelete = async (item) => {
    console.log(item.labServiceId);
    try {
      await axiosInstance.delete(`/lab/api/Lab/DeleteInvestigation`, {
        data: { labId: item?.labServiceId },
      });
      toast.success("Investigation deleted successfully");
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Failed to delete investigation");
    }
  };
  const handleChangeEditForm = (event) => {
    event.preventDefault();
    const { name, value } = event.currentTarget;
    const copyArr = [...formDetails];
    const requiredDetailIndex = copyArr.findIndex(
      (detail) => detail.name === name
    );
    const newDetail = { ...copyArr[requiredDetailIndex], value };
    copyArr[requiredDetailIndex] = newDetail;
    setFormDetails(copyArr);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormDetails(editFormData);
  };

  const handleClickView = (item) => {
    setItemInview(item);
  };

  const handleBtnsClick = (text) => {
    alert(`${text} button clicked`);
    return text === "Proceed" && setEmailSuccessful(true);
  };

  const handleAddInvestigationClick = () => {
    setShowAddInvestigationModal(true);
  };
  const handleUploadPriceListClick = () => {
    setShowUploadPage(true);
    navigation("/investigation/upload-pricelist");
  };
  useEffect(() => {
  }, [showUploadPage]);
  const count = async () => {
    try {
      const response = await axiosInstance.get(
        `lab/api/AdminReport/GetUnResolvedList/UnResolvedList?LabId=${labID}`
      );
      const { data, total, totalPages } = response.data.data;

      if (!Array.isArray(data)) {
        throw new Error("API response data is not an array");
      }

      const sortedData = data.sort((a, b) =>
        a.investigationName.localeCompare(b.investigationName)
      );

      setUnresolvedData(sortedData);
      setTotalItems(total);
      setTotalPages(totalPages);

      const conflictItems = data.filter((item) => item.itemStatus === 0);
      setConflictCount(conflictItems.length);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching unresolved data:", error);
      setIsError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    count();
  }, [labID, page.pageNumber, page.pageSize]);

  return (
    <HomeWrapper>
      {changeEmail ? (
        <EmailAddressChange onClickBackBtn={() => setChangeEmail(false)} />
      ) : itemInView ? (
        <ViewUnresolved
          name={itemInView.investigationName}
          category={itemInView.category}
          selectedName={""}
          selectedCategory={""}
          onClickBtn={handleBtnsClick}
          goBack={() => setItemInview(-1)}
          onSubmit={(e) => e.preventDefault()}
          onClickClose={() => {}}
        />
      ) : showConflicts ? (
        <UnresolvedPriceList
          goBack={() => setShowConflicts(false)}
          conflictCount={conflictCount}
          clickAction={handleClickView}
        />
      ) : (
        <>
          <HomeTop>
            {conflictCount > 0 && (
              <ConflictsDisplay $active onClick={() => setShowConflicts(true)}>
                <InfoIcon />
                <InfoText $conflictsCount={conflictCount} />
              </ConflictsDisplay>
            )}
            <SearchForm>
              <SearchInput />
              <SearchIcon />
            </SearchForm>
          </HomeTop>
          <PriceListWrapper>
            <div className="flex items-center justify-between">
              <PageTitle>Price List Breakdown</PageTitle>
              <div className="flex gap-8">
                <Button
                  btnText={
                    <>
                      <span className="text-primary">+</span> Add Investigation
                    </>
                  }
                  className="border border-primary py-2 px-2 rounded-lg"
                  onClick={handleAddInvestigationClick}
                />
                <Button
                  btnText={
                    <>
                      <span>
                        <UploadIcon className="filter brightness-0 saturate-100 invert sepia-100 hue-rotate-179" />
                      </span>
                      Upload Price List
                    </>
                  }
                  className="bg-primary text-white px-4 flex flex-row gap-2 rounded-lg py-2"
                  onClick={handleUploadPriceListClick}
                />
              </div>
            </div>
            <PriceListTableWrapper>
              <Table
                headers={priceListTableHeaders}
                data={priceListData.map((item, index) => ({
                  ...item,
                  "investigation name": item.investigationName,
                  actions: (
                    <div className="flex gap-4">
                      <Button
                        className="text-red-500"
                        btnText="Delete"
                        onClick={() => handleDelete(item)}
                      />
                      <Button
                        className="text-primary"
                        btnText="Edit"
                        onClick={() => handleClickAction("Edit", item)}
                      />
                    </div>
                  ),
                }))}
              />
              {/* <Pagination
                incrementPageNumber={incrementPageNumber}
                decrementPageNumber={decrementPageNumber}
                handlePageNumber={handlePageNumber}
                isLoading={isLoading}
                isError={isError}
                pageNumber={currentPage}
                totalPages={totalPages}
                total={totalItems}
                pageSize={page.pageSize}
                pagesToShow={5}
              /> */}
            </PriceListTableWrapper>
          </PriceListWrapper>
        </>
      )}
      {showUploadPage && <UploadPricelist />}
      {/* {showUploadPage || <UploadPricelist />} */}
      <Modal isOpen={isModalOpen} closeModal={handleCloseModal}>
        <EditModal
          formData={formDetails}
          title="Edit Price"
          onChange={handleChangeEditForm}
          onSubmit={handleSave}
        />
      </Modal>
      <SuccessModal
        isOpen={emailSuccessful}
        closeModal={() => setEmailSuccessful(false)}
        className={`w-2/5`}
      >
        <div className="flex flex-col gap-6">
          <p className="m-0 p-0 text-center text-base font-normal">
            We’ve sent a link to your email address:{" "}
            <strong className="font-bold">{emailAddress}</strong> to easily
            update your medications and price list
          </p>
          <Button
            className="bg-primary w-full text-secondaryLight rounded-button py-4"
            btnText="Change Email Address"
            onClick={() => {
              setChangeEmail(true);
              setEmailSuccessful(false);
            }}
          />
        </div>
      </SuccessModal>
      <AddInvestigationModal
        isOpen={showAddInvestigationModal}
        closeModal={() => setShowAddInvestigationModal(false)}
      />
    </HomeWrapper>
  );
};

export default Home;
