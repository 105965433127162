import React from 'react'
import Modal from './Modal'
import Input from '../Input'
import { SearchIcon } from '../TarrifBreakdown/TarrifBreakdown.styled'
import { ReactComponent as RedCloseIcon } from "../../assets/svgs/RedCloseIcon.svg";
import Button from '../Button';
import Checkbox from '../Checkbox';

const AddMedicationModal = ({  name,
    category,
    selectedName,
    selectedCategory,
    onSubmit,
    onClickClose,
      onClickBtn,
      isOpen,
    closeModal
  }) => {
  return (
    <div>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <div className="w-[40vw] flex flex-col gap-12  mt-6">
          <h1 className='font-bold text-deepBlue text-xl'>Add Medication</h1>
          <div>
            <form
              className="flex flex-col w-full pb-2 gap-5"
              onSubmit={onSubmit}
            >
              <div className="relative flex flex-col border-b-2 border-b-primary">
                <Input type="search" label="Name of Drug" />
                <SearchIcon $toRight color="#444448" />
              </div>
              <div className="relative flex flex-col border-b-2 border-b-primary">
                <Input type="text" label="Drug Price" />
              </div>

              <div className="w-full flex flex-col gap-5">
                <div className="w-full flex items-center justify-between">
                  <span>Selected Drugs</span>
                  <RedCloseIcon
                    onClick={onClickClose}
                    className="cursor-pointer"
                  />
                </div>
                <div className="w-full p-5 flex flex-col gap-7 rounded-[0.625rem] border border-gray-200">
                  <p>
                    Brand Name: <span>{selectedCategory}</span>
                  </p>
                  <p>
                    Generic Name: <span>{selectedName}</span>
                  </p>
                  <p>
                    Formulation: <span>{selectedName}</span>
                  </p>

                </div>
                <div className="flex items-center ml-auto ">
                  <Button
                    btnText="Submit"
                    className=" bg-primary text-base font-medium text-white py-2 px-10 rounded-lg"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AddMedicationModal