import React from "react";
import { IoClose } from "react-icons/io5";
import Completed from "../../assets/completed.png";
import { motion } from "framer-motion";

const SuccessModal = ({
  isOpen,
  closeModal,
  children,
  className,
  wrapperClassName,
}) => {
  const appBody = document.querySelector("body");
  appBody.style.overflow = isOpen ? "hidden" : "auto";
  const handleClose = (e) => {
    if (e.target.id === "wrapper") closeModal();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50">
      <div
        className={`fixed inset-0 bg-black bg-opacity-50`}
        onClick={handleClose}
      ></div>
      <div
        className={`${className} flex flex-col gap-6 items-center fixed z-10 bg-white rounded-lg p-4 md:p-8`}
      >
        <button
          className="text-black md:text-2xl font-semibold text-lg absolute top-0 right-0 m-4"
          onClick={closeModal}
        >
          <IoClose />
        </button>
        <div className="flex justify-center items-center w-60 aspect-[5/4] mt-5">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{
              opacity: [1, 0, 1],
              transition: { duration: 3, repeat: Infinity },
            }}
            src={Completed}
            alt=" "
          />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default SuccessModal;
