import { useContext, useState } from "react";
import { OrdersContext } from "./context";
import { BackBtn, PriceListTableWrapper } from "../Home/Home.styled";
import {
  invoiceDetailsTableHeaders,
  naira,
  restructured,
  returnInfo,
  returnTableData,
} from "./data";
import Table from "../../../components/Table";
import Button from "../../../components/Button";
import ResultUploadModal from "../../../components/modals/ResultUploadModal";

const OrderDetails = ({ currentOrder }) => {
  const { values, setValues } = useContext(OrdersContext);
  const { currentView, currentOrderIndex } = values;
  const [isModalOpen, setIsModalOpen] = useState(false);

  let date = "";
  let name = "";
  let sex = "";
  let action = null;
  const tableData = returnTableData({ currentView });
  if (
    !tableData ||
    currentOrderIndex < 0 ||
    currentOrderIndex >= tableData.length
  ) {
    return <div>Error: Unable to retrieve table data.</div>;
  }

  const currentData = tableData[currentOrderIndex];
  if (currentData) {
    ({ date, name, sex, action } = currentData);
  } else {
    // Handle null data or set default values
  }
  console.log(values);
  console.log(returnTableData({ currentView }));

  const handleGoBack = () => {
    setValues((prevValues) => ({ ...prevValues, currentOrderIndex: -1 }));
  };

  const totalPriceSum = restructured({
    arr: currentOrder?.investigation,
  }).reduce((total, item) => Number(total) + Number(item.price), 0);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleProceedClick = () => {
    openModal();
  };

  return (
    <div className="flex flex-col w-full gap-12 pb-8 overflow-auto">
      <span className="flex items-center gap-6">
        <BackBtn
          className="text-primary w-6 h-6 cursor-pointer"
          onClick={handleGoBack}
        />
        Order Details
      </span>
      <div className="flex flex-col bg-secondaryLight rounded-lg px-7 py-12 w-full">
        <div className="flex items-center justify-between w-full">
          <span className="font-semibold text-2xl text-blackText">
            Invoice Request Date
          </span>
          <span className="font-medium text-base text-grey">{date}</span>
        </div>
        <div className="flex flex-col gap-12">
          {returnInfo({
            name: currentOrder?.patientName,
            sex: currentOrder?.patientSex,
            age: currentOrder?.patientAge,
            doctorName: currentOrder?.doctorsName,
            lmdId: currentOrder?.doctorsLmdId,
          }).map((info, index) => (
            <div className={`flex flex-col w-full`} key={index}>
              <span className="text-primary font-bold text-lg">
                {info.title}
              </span>
              <div className="flex items-center justify-between w-full">
                {info.content.map((item, idx) => (
                  <div className="flex flex-col" key={idx}>
                    <span
                      className={`font-semibold text-base ${
                        index === 1
                          ? idx === 2
                            ? "text-transparent"
                            : "text-blackText"
                          : "text-blackText"
                      }`}
                    >
                      {item.label}:
                    </span>
                    <span
                      className={`font-medium text-base ${
                        index === 1
                          ? idx === 2
                            ? "text-transparent"
                            : "text-grey"
                          : "text-grey"
                      }`}
                    >
                      {item.value}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <PriceListTableWrapper
        className="bg-secondaryLight rounded-lg pt-4"
        $bottomBorder
      >
        <h1 className="text-[#444448] font-semibold text-2xl pl-32">
          Recommended Results
        </h1>
        <Table
          headers={invoiceDetailsTableHeaders}
          data={restructured({ arr: currentOrder?.investigation })}
        />
      </PriceListTableWrapper>
      <div className="flex items-center justify-between bg-secondaryLight rounded-lg py-5 px-7">
        <span className="text-blackText font-medium text-base">Total:</span>
        <span className="font-bold text-base text-blackText w-[150px]">
          {naira}
          {totalPriceSum}
        </span>
      </div>
      <Button
        btnText="Upload Result"
        onClick={handleProceedClick}
        className="ml-auto bg-primary text-secondaryLight rounded-[10px] font-medium py-2 text-base px-8 "
      />
      <ResultUploadModal isOpen={isModalOpen} closeModal={handleCloseModal} />
    </div>
  );
};

export default OrderDetails;
