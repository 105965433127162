import Logo from "../assets/logo.png";
import Group from "../assets/Group.png";
import Registered from "../assets/registered.png";
import { Link } from "react-router-dom";
import { HiBell } from "react-icons/hi";
import { useSelector } from "react-redux";
const NavBar = ({ showDetails }) => {
  const facilityName = useSelector((state) => state?.user?.selectedFacility?.name);
  const facilityInitials = useSelector((state) => state?.user?.selectedFacility?.initials);
  return (
    <div className="bg-secondaryLight px-4 md:px-16 py-4 flex space-x-1 items-center justify-between fixed w-full h-20 z-30">
      <Link to="/" className="flex items-center">
        <img className="flex-shrink-0 w-8 h-6 " src={Logo} alt="logo" />
        <img className="flex-shrink-0 w-32 h-5 " src={Group} alt="group" />
        <img
          className="flex-shrink-0 w-3 h-3"
          src={Registered}
          alt="registered"
        />
      </Link>
      {showDetails && (
        <div className="flex items-center gap-10">
          <HiBell className="text-primary w-4 h-5" />
          <div className="flex items-center gap-2">
            <span className="whitespace-nowrap">{facilityName}</span>
            <span className="bg-secondary rounded-full p-2.5 font-bold text-base text-primary flex items-center justify-center w-10 h-10">
              {facilityInitials}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
