import { useContext, useState } from "react";
import { OrdersContext } from "./context";
import { BackBtn, PriceListTableWrapper } from "../Home/Home.styled";
import {
  invoiceDetailsTableHeaders,
  naira,
  restructured,
  returnInfo,
  returnTableData,
} from "./data";
import Table from "../../../components/Table";
import Button from "../../../components/Button";
import ConfirmOrderModal from "../../../components/modals/ConfirmOrderModal";

const OrderDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleProceedClick = () => {
    openModal();
  };

  const { values, setValues } = useContext(OrdersContext);
  const { currentView, currentOrderIndex } = values;
  let date = "";
  let name = "";
  let sex = "";
  let action = null;

  const tableData = returnTableData({ currentView });
  if (
    !tableData ||
    currentOrderIndex < 0 ||
    currentOrderIndex >= tableData.length
  ) {
    // Handle error or return default values
    return <div>Error: Unable to retrieve table data.</div>;
  }

  // Destructure properties from tableData[currentIndex]
  const currentData = tableData[currentOrderIndex];
  if (currentData) {
    ({ date, name, sex, action } = currentData);
  } else {
    // Handle null data or set default values
  }
  console.log(values);
  console.log(returnTableData({ currentView }));

  const handleGoBack = () => {
    setValues((prevValues) => ({ ...prevValues, currentOrderIndex: -1 }));
  };

  // const handleClickAddNew = (index) => {
  //   setValues((prevValues) => ({ ...prevValues, addNewIndex: index }));
  // };

  const totalPriceSum = restructured().reduce(
    (total, item) => total + item.price,
    0
  );

  return (
    <div className="flex flex-col w-full gap-12 pb-8 overflow-auto">
      <span className="flex items-center gap-6">
        <BackBtn
          className="text-primary w-6 h-6 cursor-pointer"
          onClick={handleGoBack}
        />
        Order Details
      </span>
      <div className="flex flex-col bg-secondaryLight rounded-lg px-7 py-12 w-full">
        <div className="flex items-center justify-between w-full">
          <span className="font-semibold text-2xl text-blackText">
            Invoice Request Date
          </span>
          <span className="font-medium text-base text-grey">{date}</span>
        </div>
        <div className="flex flex-col gap-12">
          {returnInfo({
            name,
            sex,
            age: "30",
            doctorName: "Francis Eton",
            lmdId: "ccll12344",
          }).map((info, index) => (
            <div className={`flex flex-col w-full`} key={index}>
              <span className="text-primary font-bold text-lg">
                {info.title}
              </span>
              <div className="flex items-center justify-between w-full">
                {info.content.map((item, idx) => (
                  <div className="flex flex-col" key={idx}>
                    <span
                      className={`font-semibold text-base ${
                        index === 1
                          ? idx === 2
                            ? "text-transparent"
                            : "text-blackText"
                          : "text-blackText"
                      }`}
                    >
                      {item.label}:
                    </span>
                    <span
                      className={`font-medium text-base ${
                        index === 1
                          ? idx === 2
                            ? "text-transparent"
                            : "text-grey"
                          : "text-grey"
                      }`}
                    >
                      {item.value}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <PriceListTableWrapper
        className="bg-secondaryLight rounded-lg pt-4"
        $bottomBorder
      >
        <h1 className="text-[#444448] font-semibold text-2xl pl-32">
          Recommended Results
        </h1>
        <Table headers={invoiceDetailsTableHeaders} data={restructured()} />
      </PriceListTableWrapper>
      <div className="flex justify-between bg-secondaryLight rounded-lg py-5 px-7">
        <span className="text-blackText font-medium text-base">Total:</span>
        <span className="font-bold text-base text-blackText w-[160px]">
          {naira}
          {totalPriceSum}
        </span>
      </div>
      <div className="flex flex-row justify-between px-3">
        <span className=" text-center">Note: Patient will be here in the next 5 mins. Please pack all these drugs before this time.</span>
      <Button
        btnText="Confirm Order"
        onClick={handleProceedClick}
        className=" bg-primary text-secondaryLight rounded-[10px] font-medium py-2 text-base px-8 "
      />

      </div>
      <ConfirmOrderModal isOpen={isModalOpen} closeModal={handleCloseModal} />

    </div>
  );
};

export default OrderDetails;
