
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL_NEW
const BaseUrl = process.env.REACT_APP_BASE_URL_API
const newBaseUrl = process.env.REACT_APP_BASE_URL_NEW
export const API = {
    baseUrl:baseUrl,
    BaseUrl:BaseUrl,
    newBaseUrl:newBaseUrl
}

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_Ocp_Apim_Subscription_Key,
    token: localStorage.getItem("accessToken")
  }
});

export default axiosInstance;
