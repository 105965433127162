import React from 'react'
import Load from "../assets/preloader.gif"

const Loader = ({className}) => {
  return (
        <div className={`spinner-container ${className} h-screen top-0 bg-transparent flex justify-center items-center`}>
          <img src={Load} alt="" className="m-auto rounded-full w-44 h-44" />
        </div>
  )
}

export default Loader