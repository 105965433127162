import React from 'react'
import Modal from './Modal'
import Input from '../Input'
import Button from '../Button'

const ConfirmOrderModal = ({isOpen, closeModal}) => {
    
  return (
    <div>
        <Modal  isOpen={isOpen} closeModal={closeModal} >
            <div className="w-[40vw] flex flex-col gap-6 mt-6 ">
                <h1 className="text-deepBlue font-bold text-xl">Confirm Order</h1>
                <label htmlFor="">Enter order confirmation codes</label>
                <Input type="text"  className=" border border-none"/>
                <Button btnText="Submit"
                className="bg-primary p-3 items-center font-medium text-sm rounded-lg text-white w-full mt-2" />
            </div>

        </Modal>
    </div>
  )
}

export default ConfirmOrderModal