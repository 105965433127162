import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API } from './constants';

export const BaseQuery = fetchBaseQuery({
  baseUrl: API.baseUrl,
  prepareHeaders: (headers, { getState }) => {
    headers.set('Content-Type', 'application/json');
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    headers.set('Ocp-Apim-Subscription-Key', process.env.REACT_APP_Ocp_Apim_Subscription_Key);

    return headers;
  },
});
