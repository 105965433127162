import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  priceListData: [],
  isLoading: false,
  isError: false,
};

const priceListSlice = createSlice({
  name: 'priceList',
  initialState,
  reducers: {
    setPriceListData: (state, action) => {
      state.priceListData = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsError: (state, action) => {
      state.isError = action.payload;
    },
  },
});

export const { setPriceListData, setIsLoading, setIsError } = priceListSlice.actions;

export const priceListReducer = priceListSlice.reducer;

export default priceListSlice.reducer;
