import React from "react";
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";

function Pagination({
  incrementPageNumber,
  decrementPageNumber,
  handlePageNumber,
  isLoading,
  isError,
  pageNumber,
  totalPages,
  total,
  pageSize,
  pagesToShow,
}) {
  const firstResultIndex = (pageNumber - 1) * pageSize + 1;
  const lastResultIndex = Math.min(pageNumber * pageSize, total);
  const startPage = Math.max(1, pageNumber - Math.floor(pagesToShow / 2));
  const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

  return (
    <div className="w-full px-4">
      {!isLoading && !isError && (
        <div className="flex justify-between gap-2 items-center mt-3">
          <div className="flex justify-start gap-2 items-center mt-3">
            {pageNumber > 1 && (
              <button
                onClick={decrementPageNumber}
                className="w-[2rem] h-[2rem]"
              >
                <TfiArrowCircleLeft />
              </button>
            )}

            {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
              <button
                key={startPage + index}
                onClick={() => handlePageNumber(startPage + index)}
                disabled={startPage + index === pageNumber}
                className={`text-[#1A1A1A] ${
                  pageNumber === startPage + index
                    ? "bg-primary text-white"
                    : ""
                } hover:bg-primary hover:text-white text-center rounded-[50%] w-[2rem] h-[2rem]`}
              >
                {startPage + index}
              </button>
            ))}

            {pageNumber < totalPages && (
              <button
                onClick={incrementPageNumber}
                className="w-[2rem] h-[2rem]"
              >
                <TfiArrowCircleRight />
              </button>
            )}
          </div>
          {!isNaN(lastResultIndex) && (
            <div className="text-[#1A1A1A]">{`Results: ${firstResultIndex} - ${lastResultIndex} of ${total}`}</div>
          )}
        </div>
      )}
    </div>
  );
}

export default Pagination;
