import Button from "../../../components/Button";

export const tabs = ["Invoice", "Orders"];

export const naira = '₦'

export const returnTableHeaders = ({ currentView }) => {
  return [
    "Name",
    `${currentView === "Invoice" ? "Invoice" : "Order"} ID`,
    "Sex",
    "Date",
    "Action",
  ];
};

export const orderStatus = ["Pending", "Delivered"];

export const returnTableData = ({ onClickView, currentView }) => {
  const currentDate = new Date().toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });


  return Array.from({ length: 6 }, (_, index) => ({
    name: "John Doe",
    [`${currentView === "Invoice" ? "invoice" : "order"} id`]: "2346567",
    sex: "Male",
    date: transformDateFormat(currentDate),
    action: onClickView ? (
      <Button
        btnText="View"
        onClick={() => onClickView(index)}
        className="text-primary font-bold text-base"
      />
    ) : null,
  }));
};

export function transformDateFormat(dateString) {
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const time = `${hours}:${minutes}`;

  return `${formattedDate} ${time}`;
}
export const returnInfo = ({ name, sex, age, doctorName, lmdId }) => {
  return [
    {
      title: "Patient Info",
      content: [
        { label: "Name", value: name },
        { label: "Sex", value: sex },
        { label: "Age", value: age },
      ],
    },
    {
      title: "Doctor’s Info",
      content: [
        { label: "Name", value: doctorName },
        { label: "LMD ID", value: lmdId },
        { label: "Age", value: age },
      ],
    },
  ];
};
export const transactions = ({ date, serviceType, name, paymentMethod }) => {
  return [
    {
      content: [
        { label: "Date Created", value: date },
        { label: "Service Type", value: serviceType },
      ],
    },
    {
      content: [
        { label: "Name", value: name },
        { label: "Payment Method", value: paymentMethod },
      ],
    },
  ];
};
export const invoiceDetailsTableHeaders = [
  "",
  "Name of Medication",
  "Service",
  "Price",
  " ",
];

const medicationNames = [
  "Albumin - (Serum)",
  "Bilirubin (direct) - (serum)",
  "Blood Differential test",
];

const obj = {
  "": "",
  "name of medication": "",
  service: "",
  price: "",
  " ": "",
};

export const restructured = () =>
  medicationNames.map((name, index) => ({
    ...obj,
    "": index + 1,
    "name of medication": name,
    service: "Laboratory",
    price: 200,
  }));

export const noteFromDoctor =
  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt";

export const addNewFormData = [
  {
    label: "Brand Name *",
    placeholder: "Enter Brand name",
    type: "text",
  },
  { label: "Price *", placeholder: "Enter Price", type: "number" },
];

export const invoicePreviewTableHeaders = [
  "medication Name",
  "Quantity",
  "Price",
  "Actions",
];

export const returninvoicePreviewTableData = ({ onClickAction } = {}) => {
  const actionTexts = ["Delete", "Edit"];
  return Array.from({ length: 3 }, (_, index) => ({
    "medication name": "Hepatitis B",
    quantity: 1,
    price: "N200",
    actions: (
      <div className="flex items-center gap-4">
        {actionTexts.map((text) => (
          <Button
            key={text}
            btnText={text}
            onClick={() => onClickAction && onClickAction({ text, index })}
            className={`${
              text === "Delete" ? "text-red-500" : "text-primary"
            } font-semibold text-base`}
          />
        ))}
      </div>
    ),
  }));
};
