import { BackBtn, InfoIcon, PriceListTableWrapper } from "../Home/Home.styled";
import { useContext, useEffect, useState } from "react";
import { OrdersContext } from "./context";
import Table from "../../../components/Table";
import {
  invoicePreviewTableHeaders,
  returninvoicePreviewTableData,
} from "./data";
import Button from "../../../components/Button";
import SuccessModal from "../../../components/modals/SuccessModal";
import InvoiceEditModal from "../../../components/modals/InvoiceEditModal";
import { usePostCreateApiHook } from "../../../api/hooks";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const InvoicePreview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const { values, setValues } = useContext(OrdersContext);
  const { createInvoice, invoiceCreated, selectedInvoiceDetails } = values;
  const { post, loading } = usePostCreateApiHook();
  const navigate = useNavigate(); // Create a navigate function

  useEffect(() => {
    console.log('Selected Invoice Details:', selectedInvoiceDetails);
  }, [selectedInvoiceDetails]);

  const handleGoBack = () => {
    setValues((prevValues) => ({
      ...prevValues,
      createInvoice: false,
      invoiceCreated: false,
    }));
    navigate("/facility/orders"); // Navigate back to the orders page
  };

  const handlePostInvoice = async () => {
    const payload = {
      id: selectedInvoiceDetails?.id,
      vendorId: selectedInvoiceDetails?.facilityId,
      userId: selectedInvoiceDetails?.userId,
      claimId: selectedInvoiceDetails?.claimId,
      purchaseRequestId: selectedInvoiceDetails?.services[0]?.purchaseRequestId, // Assuming all services have the same purchaseRequestId
      isPaid: selectedInvoiceDetails?.proFormasent,
      isDeposite:selectedInvoiceDetails?.proFormasent,
      // createdAt: selectedInvoiceDetails?.createdAt,
      // updatedAt: 
      services: selectedInvoiceDetails?.services.map(service => ({
        proFormaInvoiceId: service.id,
        serviceName: service.serviceName,
        description: "",
        masterId: service.masterId,
        quantity: service.quantity || 1,
        price: service.price,
        serviceType: service.serviceType,
      })),
    };

    try {
      await post("/transaction/Invoice/CreateProFormaInvoice", payload);
      setValues((prevValues) => ({ ...prevValues, invoiceCreated: true }));
      toast.success("Invoice posted successfully");
      navigate("/investigation/orders"); // Navigate back to the orders page upon success
    } catch (error) {
      toast.error("Failed to post invoice");
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClickAction = ({ text, index }) => {
    if (text === "Edit") {
      const currentDetail = returninvoicePreviewTableData({
        arr: selectedInvoiceDetails?.services || [],
        onClickAction: handleClickAction,
      })[index];
      setFormData([
        { label: "Investigation Name", type: "text", name: "investigationName", value: currentDetail["investigation name"] },
        { label: "Quantity", type: "number", name: "quantity", value: currentDetail.quantity },
        { label: "Price", type: "text", name: "price", value: currentDetail.price },
      ]);
      setIsModalOpen(true);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) =>
      prevFormData.map((data) =>
        data.name === name ? { ...data, value } : data
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data: ", formData);
    handleCloseModal();
  };

  return (
    <>
      {createInvoice && (
        <div className="flex flex-col gap-5 pb-10">
          <span className="flex items-center gap-4">
            <BackBtn
              className="text-primary w-6 h-6 cursor-pointer"
              onClick={handleGoBack}
            />
            Invoice Preview
          </span>
          <p className="flex items-center justify-center bg-lightOrange gap-5 py-3 font-medium text-base text-black">
            <InfoIcon />
            Any changes of prices here will reflect on master list
          </p>
          <PriceListTableWrapper>
            <Table
              headers={invoicePreviewTableHeaders}
              data={returninvoicePreviewTableData({
                arr: selectedInvoiceDetails?.services,
                onClickAction: handleClickAction,
              })}
            />
          </PriceListTableWrapper>
          <Button
            btnText={loading ? "Posting..." : "Post Invoice"}
            className="ml-auto bg-primary rounded-button py-4 px-24 text-secondaryLight"
            onClick={handlePostInvoice}
            disabled={loading}
          />
          <SuccessModal
            isOpen={invoiceCreated}
            closeModal={handleGoBack}
          ></SuccessModal>
          <InvoiceEditModal
            formData={formData}
            onChange={handleFormChange}
            onSubmit={handleSubmit}
            isOpen={isModalOpen}
            closeModal={handleCloseModal}
          />
        </div>
      )}
    </>
  );
};

export default InvoicePreview;
