import { useContext } from "react";
import { OrdersContext } from "./context";
import { BackBtn, PriceListTableWrapper } from "../Home/Home.styled";
import {
  addNewFormData,
  invoiceDetailsTableHeaders,
  restructured,
  returnInfo,
} from "./data";
import Table from "../../../components/Table";
import Button from "../../../components/Button";
import Modal from "../../../components/modals/Modal";
import Input from "../../../components/Input";

const InvoiceDetails = ({ invoice }) => {
  const { values, setValues } = useContext(OrdersContext);
  const { currentInvoiceIndex, addNewIndex } = values;

  const handleGoBack = () => {
    setValues((prevValues) => ({ ...prevValues, currentInvoiceIndex: -1 }));
  };

  const handleClickAddNew = (index) => {
    setValues((prevValues) => ({ ...prevValues, addNewIndex: index }));
  };

  const handleClickCreateInvoice = () => {
    setValues((prevValues) => ({
      ...prevValues,
      createInvoice: true,
    }));
  };

  if (!invoice) {
    return <div>No Invoice Selected</div>;
  }
  return (
    <div className="flex flex-col w-full gap-12 pb-8 overflow-auto">
      <span className="flex items-center gap-6">
        <BackBtn
          className="text-primary w-6 h-6 cursor-pointer"
          onClick={handleGoBack}
        />
        Laboratory Invoice Details
      </span>
      <div className="flex flex-col bg-secondaryLight rounded-lg px-7 py-12 w-full">
        <div className="flex items-center justify-between w-full">
          <span className="font-semibold text-2xl text-blackText">
            Invoice Request Date
          </span>
          <span className="font-medium text-base text-grey">{invoice.createdAt}</span>
        </div>
        <div className="flex flex-col gap-12">
          {returnInfo({
            name: invoice.patientName,
            sex: invoice.patientSex,
            age: invoice.patientAge,
            doctorName: invoice.doctorName,
            lmdId: invoice.doctorCCLId,
          }).map((info, index) => (
            <div className={`flex flex-col w-full`} key={index}>
              <span className="text-primary font-bold text-lg">
                {info.title}
              </span>
              <div className="flex items-center justify-between w-full">
                {info.content.map((item, idx) => (
                  <div className="flex flex-col" key={idx}>
                    <span
                      className={`font-semibold text-base ${
                        index === 1
                          ? idx === 2
                            ? "text-transparent"
                            : "text-blackText"
                          : "text-blackText"
                      }`}
                    >
                      {item.label}:
                    </span>
                    <span
                      className={`font-medium text-base ${
                        index === 1
                          ? idx === 2
                            ? "text-transparent"
                            : "text-grey"
                          : "text-grey"
                      }`}
                    >
                      {item.value}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <PriceListTableWrapper
        className="bg-secondaryLight rounded-lg pt-4"
        $bottomBorder
      >
        <Table
          headers={invoiceDetailsTableHeaders}
          data={restructured({ arr: invoice?.services})}
        />
        <div className="flex justify-end">
          <Button
            btnText="Add new Item"
            className="bg-primary text-secondaryLight rounded-[10px] font-semibold p-2.5 text-base m "
            onClick={() => handleClickAddNew(currentInvoiceIndex)}
          />
        </div>
        <div className="flex flex-col gap-7 p-4">
          <span className="font-semibold text-blackText text-2xl">
            Note from Doctor
          </span>
          <p className="border border-gray-300 rounded-button p-8 aspect-[141/38] text-blackText">
            {invoice?.docotorsNote}
          </p>
        </div>
      </PriceListTableWrapper>
      <Button
        btnText="Create invoice"
        className="ml-auto bg-primary text-secondaryLight rounded-[10px] font-medium p-2.5 text-base"
        onClick={handleClickCreateInvoice}
      />
      <Modal
        isOpen={addNewIndex >= 0}
        closeModal={() => handleClickAddNew(-1)}
        className="w-2/5"
      >
        <form action="" className="flex flex-col gap-6">
          <span className="font-bold text-deepBlue text-xl">Add new Item</span>
          {addNewFormData.map((data) => (
            <Input
              key={data.label}
              label={data.label}
              placeholder={data.placeholder}
              type={data.type}
            />
          ))}
          <Button
            btnText="Submit"
            className="w-full bg-primary text-secondaryLight rounded-[10px] font-medium p-2.5 text-base"
          />
        </form>
      </Modal>
    </div>
  );
};

export default InvoiceDetails;
