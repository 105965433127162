import styled from "styled-components";
import Input from "../Input";
import { CiSearch } from "react-icons/ci";
import Button from "../Button";

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  justify-content: space-between;
  box-shadow: 0px 2px 16px 0px #c0c0c040;
`;

export const TarrifTab = styled.span`
  padding: 0.5rem;
  background-color: ${({ $active }) => ($active ? "#2CBCEF" : "transparent")};
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #${({ $active }) => ($active ? "fff" : "444448")};
  cursor: pointer;
`;

export const SearchInput = styled(Input).attrs({ placeholder: "search" })`
  background-color: #fafafa;
`;

export const SearchIcon = styled(CiSearch)`
  position: absolute;
  top: 50%;
  left: ${({ $toRight }) => $toRight ? '' : '0.3rem'};
  right: ${({ $toRight }) => $toRight ? '0.3rem' : ''};
  transform: translateY(-${({ $middle }) => $middle ? 50 : 80}%);
  color: #2cbcef;
`;
export const TabContentBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;

  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }

  th {
    color: #808080;
    font-size: 1rem;
    font-weight: 400;
  }

  th,
  td {
    text-align: left;
    padding: 0.25rem 0;
  }

  thead {
    tr:first-child {
      background-color: transparent;
    }
  }

  tr {
    background: #fafafa;
    padding: 1.5rem;

    td:last-child {
      font-weight: 600;
    }

    td {
      font-weight: 400;
      color: #444448;
    }
  }
`;

export const BtnsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 90%;
`;

export const ActionBtn = styled(Button)`
  background-color: #${({ $accept }) => ($accept ? "2CBCEF" : "")};
  border: 1px solid #${({ $accept }) => ($accept ? "2CBCEF" : "EB5757")};
  color: #${({ $accept }) => ($accept ? "fff" : "EB5757")};
  border-radius: 1.25rem;
  padding: 1rem 0;
  font-size: 0.875rem;
  font-weight: 500;
`;
