import { useEffect, useState } from "react";
import {
  ConflictsDisplay,
  HomeTop,
  HomeWrapper,
  InfoIcon,
  InfoText,
  PageTitle,
  PriceListTableWrapper,
  PriceListWrapper,
  SearchForm,
  SearchInput,
} from "../Home/Home.styled";
import { SearchIcon } from "../../../components/TarrifBreakdown/TarrifBreakdown.styled";
import {
  pharmpriceListTableHeaders,
  returnUnresolvedTableData,
  emailAddress,
  conflictCount,
} from "../Home/data";
import { editFormData, pharmarcyreturnPriceListTableData } from "./data";
import { ReactComponent as UploadIcon } from "../../../assets/svgs/upload-01.svg";

import Table from "../../../components/Table";
import Modal from "../../../components/modals/Modal";
import EditModal from "../../../components/modals/EditModal";
import SuccessModal from "../../../components/modals/SuccessModal";
import Button from "../../../components/Button";
import EmailAddressChange from "../Home/EmailAddressChange";
import UnresolvedProceduralList from "./UnresolvedProceduralList";
import ProceduralUnresolved from "./ProceduralUnresolved";
import AddInvestigationModal from "../../../components/modals/AddInvestigationModal";
import UploadPricelist from "./UploadPricelist";
import { useNavigate } from "react-router-dom";
import AddMedicationModal from "../../../components/modals/AddMedicationModal";

const Home = ({ isOpen, closeModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPriceDetailsModalOpen, setIsPriceDetailsModalOpen] = useState(false);
  const [formDetails, setFormDetails] = useState(editFormData);
  const [showConflicts, setShowConflicts] = useState(false);
  const [emailSuccessful, setEmailSuccessful] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [indexInView, setIndexInview] = useState(-1);
  const [showAddMedicationModal, setShowAddMedicationModal] = useState(false);
  const [showUploadPage, setShowUploadPage] = useState(false);
  const [currentDetail, setCurrentDetail] = useState(null);
  const navigation = useNavigate();

  const handleClickAction = (text, index) => {
    const currentDetail = pharmarcyreturnPriceListTableData()[index];
    if (text === "Edit") {
      setIsModalOpen(true);
      setFormDetails((prevFormDetails) =>
        prevFormDetails.map((detail) =>
          detail.name === "medicationName"
            ? { ...detail, value: currentDetail["medication name"] }
            : detail.name === "category"
            ? { ...detail, value: currentDetail.category }
            : detail.name === "price"
            ? { ...detail, value: Number(currentDetail.price.slice(1)) }
            : detail
        )
      );
    } else if (text === "View") {
      setCurrentDetail(currentDetail);
      setIsPriceDetailsModalOpen(true);
    } else if (text === "Delete") {
      // Handle delete action here
    }
  };

  const handleChangeEditForm = (event) => {
    event.preventDefault();
    const { name, value } = event.currentTarget;
    const copyArr = [...formDetails];
    const requiredDetailIndex = copyArr.findIndex(
      (detail) => detail.name === name
    );
    const newDetail = { ...copyArr[requiredDetailIndex], value };
    copyArr[requiredDetailIndex] = newDetail;
    setFormDetails(copyArr);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsPriceDetailsModalOpen(false);
    setFormDetails(editFormData);
  };

  const handleClickView = (index) => {
    setIndexInview(index);
  };

  const handleBtnsClick = (text) => {
    alert(`${text} button clicked`);
    return text === "Proceed" && setEmailSuccessful(true);
  };

  const handleAddMedicationClick = () => {
    setShowAddMedicationModal(true);
  };

  const handleUploadPriceListClick = () => {
    setShowUploadPage(true);
    navigation("/pharmacy/pharm-upload-pricelist");
  };

  const data = pharmarcyreturnPriceListTableData(handleClickAction);

  useEffect(() => {
    console.log("Table Data:", data);
  }, [data]);

  return (
    <HomeWrapper>
      {changeEmail ? (
        <EmailAddressChange onClickBackBtn={() => setChangeEmail(false)} />
      ) : indexInView >= 0 ? (
        <ProceduralUnresolved
          name={returnUnresolvedTableData()[indexInView]["medication name"]}
          category={returnUnresolvedTableData()[indexInView].category}
          selectedName={""}
          selectedCategory={""}
          onClickBtn={handleBtnsClick}
          goBack={() => setIndexInview(-1)}
          onSubmit={(e) => e.preventDefault()}
          onClickClose={() => {}}
        />
      ) : showConflicts ? (
        <UnresolvedProceduralList
          goBack={() => setShowConflicts(false)}
          conflictCount={conflictCount}
          clickAction={handleClickView}
        />
      ) : (
        <>
          <HomeTop>
            {conflictCount > 0 && (
              <ConflictsDisplay $active onClick={() => setShowConflicts(true)}>
                <InfoIcon />
                <InfoText $conflictsCount={conflictCount} />
              </ConflictsDisplay>
            )}
            <SearchForm>
              <SearchInput />
              <SearchIcon />
            </SearchForm>
          </HomeTop>
          <PriceListWrapper>
            <div className="flex items-center justify-between">
              <PageTitle>Price List Breakdown</PageTitle>
              <div className="flex gap-8">
                <Button
                  btnText={
                    <>
                      <span className="text-primary">+</span> Add Medication
                    </>
                  }
                  className="border border-primary py-2 px-2 rounded-lg"
                  onClick={handleAddMedicationClick}
                />
                <Button
                  btnText={
                    <>
                      <span>
                        <UploadIcon className="filter brightness-0 saturate-100 invert sepia-100 hue-rotate-179" />
                      </span>
                      Upload Price List
                    </>
                  }
                  className="bg-primary text-white px-4 flex flex-row gap-2 rounded-lg py-2"
                  onClick={handleUploadPriceListClick}
                />
              </div>
            </div>
            <PriceListTableWrapper>
              <Table
                headers={pharmpriceListTableHeaders}
                data={data}
              />
            </PriceListTableWrapper>
          </PriceListWrapper>
        </>
      )}
      {showUploadPage && <UploadPricelist />}
      <Modal isOpen={isModalOpen} closeModal={handleCloseModal}>
        <EditModal
          formData={formDetails}
          title="Edit Price"
          onChange={handleChangeEditForm}
          onSubmit={() => {}}
        />
      </Modal>
      <Modal isOpen={isPriceDetailsModalOpen} closeModal={handleCloseModal}>
        <div>
          <h2>Price Details</h2>
          <p>Medication Name: {currentDetail?.["medication name"]}</p>
          <p>Medication Mass: {currentDetail?.["medication mass"]}</p>
          <p>Price: {currentDetail?.price}</p>
        </div>
      </Modal>
      <SuccessModal
        isOpen={emailSuccessful}
        closeModal={() => setEmailSuccessful(false)}
        className={`w-2/5`}
      >
        <div className="flex flex-col gap-6">
          <p className="m-0 p-0 text-center text-base font-normal">
            We’ve sent a link to your email address:
            <strong className="font-bold">{emailAddress}</strong> to easily
            update your medications and price list
          </p>
          <Button
            btnText="Okay"
            className="py-4 w-full rounded-lg bg-primary text-white"
            onClick={() => setEmailSuccessful(false)}
          />
        </div>
      </SuccessModal>
      <AddInvestigationModal isOpen={isOpen} closeModal={closeModal} />
      <AddMedicationModal
        isOpen={showAddMedicationModal}
        closeModal={() => setShowAddMedicationModal(false)}
      />
    </HomeWrapper>
  );
};

export default Home;
