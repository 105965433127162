import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";
import { AnimatePresence } from "framer-motion";
import SuspenseLoader from "./SuspenseLoader";
import Orders from "../pages/Investigation/Orders";
import PharmOrders from "../pages/Pharmacy/Orders"
import PriceList from "../pages/Investigation/PriceList";
import PharmPriceList from "../pages/Pharmacy/PriceList"
import OrdersProvider from "../pages/Investigation/Orders/context";
import PharmOrdersProvider from "../pages/Pharmacy/Orders/context";
import UploadPricelist from "../pages/Investigation/PriceList/UploadPricelist";
import PharmUploadPricelist from "../pages/Pharmacy/PriceList/UploadPricelist";

import Wallet from "../pages/Investigation/Wallet";
import TariffEmail from "../pages/TariffEmail";
import { ToastContainer } from "react-toastify";
import HospitalFlowLayout from "../layouts/HospitalFlow";

const EmailVerificationPage = React.lazy(() =>
  import("../pages/EmailVerificationPage")
);
const FacilityDetails = React.lazy(() => import("../pages/FacilityDetails"));
const LoginPage = React.lazy(() => import("../pages/LoginPage"));
const Facility = React.lazy(() => import("../layouts/FacilitydetailsLayout"));
const ChooseOwnershipStatus = React.lazy(() =>
  import("../components/ChooseAccountOwnershipStatus")
);
const TarrifBreakdown = React.lazy(() =>
  import("../components/TarrifBreakdown")
);
const AddFacility = React.lazy(() => import("../components/AddFacility"));
const InvestigationFlowLayout = React.lazy(() =>
  import("../layouts/InvestigationFlow")
);
const Home = React.lazy(() => import("../pages/Investigation/Home"));
const PharmHome = React.lazy(() => import("../pages/Pharmacy/Home"))
const HospHome = React.lazy(() => import("../pages/Hospital/Home"))
const Details = React.lazy(( ) => import("../pages/Hospital/Home/Details"))
const Router = () => {
  return (
    <AnimatePresence mode="sync">
      <Suspense
        fallback={<Loader />}
        className="bg-[#EFEFF3] font-helvetica "
        style={{ position: "relative" }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <SuspenseLoader>
                <LoginPage />
              </SuspenseLoader>
            }
          />
          <Route
            path="/emailverificationpage"
            element={
              <SuspenseLoader>
                <EmailVerificationPage />
              </SuspenseLoader>
            }
          />
          <Route
            path="/tariffemail"
            element={
              <SuspenseLoader>
                <TariffEmail />
              </SuspenseLoader>
            }
          />

          <Route
            path="/facility/*"
            element={
              <SuspenseLoader>
                <Facility />
              </SuspenseLoader>
            }
          >
            <Route
              path="choose-account"
              element={
                <SuspenseLoader>
                  <ChooseOwnershipStatus />
                </SuspenseLoader>
              }
            />
            <Route
              path="add-facility"
              element={
                <SuspenseLoader>
                  <AddFacility />
                </SuspenseLoader>
              }
            />
            <Route
              path="tarrif-breakdown"
              element={
                <SuspenseLoader>
                  <TarrifBreakdown />
                </SuspenseLoader>
              }
            />
            <Route
              path="activation/:email/:type"
              element={
                <SuspenseLoader>
                  <FacilityDetails />
                </SuspenseLoader>
              }
            />
            <Route path="*" element={<p>This should be the 404 page</p>} />
          </Route>
          <Route
            path="/investigation/*"
            element={
              <SuspenseLoader>
                <OrdersProvider>
                  <InvestigationFlowLayout />
                </OrdersProvider>
              </SuspenseLoader>
            }
          >
            <Route
              path=""
              element={
                <SuspenseLoader>
                  <Home />
                </SuspenseLoader>
              }
            />
            <Route
              path="orders"
              element={
                <SuspenseLoader>
                  <Orders />
                </SuspenseLoader>
              }
            />
            <Route
              path="upload-pricelist"
              element={
                <SuspenseLoader>
                  <UploadPricelist />
                </SuspenseLoader>
              }
            />
            <Route
              path="pricelist"
              element={
                <SuspenseLoader>
                  <PriceList />
                </SuspenseLoader>
              }
            />
            <Route
              path="wallet"
              element={
                <SuspenseLoader>
                  <Wallet />
                </SuspenseLoader>
              }
            />
          </Route>
          <Route
            path="/pharmacy/*"
            element={
              <SuspenseLoader>
                <PharmOrdersProvider>
                  <InvestigationFlowLayout />
                </PharmOrdersProvider>
              </SuspenseLoader>
            }
          >
            <Route
              path=""
              element={
                <SuspenseLoader>
                  <PharmHome />
                </SuspenseLoader>
              }
            />
            <Route
              path="orders"
              element={
                <SuspenseLoader>
                  <PharmOrders />
                </SuspenseLoader>
              }
            />
            <Route
              path="pharm-upload-pricelist"
              element={
                <SuspenseLoader>
                  <PharmUploadPricelist />
                </SuspenseLoader>
              }
            />
            <Route
              path="pricelist"
              element={
                <SuspenseLoader>
                  <PharmPriceList />
                </SuspenseLoader>
              }
            />
            <Route
              path="wallet"
              element={
                <SuspenseLoader>
                  <Wallet />
                </SuspenseLoader>
              }
            />
          </Route>
          <Route
            path="/hospital/*"
            element={
              <SuspenseLoader>
                <PharmOrdersProvider>
                  <HospitalFlowLayout />
                </PharmOrdersProvider>
              </SuspenseLoader>
            }
          >
            <Route
              path=""
              element={
                <SuspenseLoader>
                  <HospHome />
                </SuspenseLoader>
              }
            />
              <Route
              path=":id"
              element={
                <SuspenseLoader>
                  <Details />
                </SuspenseLoader>
              }
            />
            <Route
              path="orders"
              element={
                <SuspenseLoader>
                  <PharmOrders />
                </SuspenseLoader>
              }
            />
            <Route
              path="pharm-upload-pricelist"
              element={
                <SuspenseLoader>
                  <PharmUploadPricelist />
                </SuspenseLoader>
              }
            />
            <Route
              path="pricelist"
              element={
                <SuspenseLoader>
                  <PharmPriceList />
                </SuspenseLoader>
              }
            />
            <Route
              path="wallet"
              element={
                <SuspenseLoader>
                  <Wallet />
                </SuspenseLoader>
              }
            />
          </Route>


          <Route path="*" element={<p>This should be the 404 page</p>} />
        </Routes>
      </Suspense>
      <ToastContainer />
    </AnimatePresence>
  );
};

export default Router;
