// import React from 'react';
// import PropTypes from 'prop-types';

// const Checkbox = ({ id, name, checked, onChange, className }) => {
//   return (
//     <input
//       type="checkbox"
//       id={id}
//       name={name}
//       checked={checked}
//       onChange={onChange}
//       className={`block bg-white appearance-none border border-gray-300 rounded-full w-14 h-7 outline-none checked:bg-blue-500 checked:translate-x-6 focus:outline-none ${className}`}
//     />
//   );
// };

// Checkbox.propTypes = {
//   id: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
//   checked: PropTypes.bool.isRequired,
//   onChange: PropTypes.func.isRequired,
//   className: PropTypes.string,
// };

// Checkbox.defaultProps = {
//   className: '',
// };

// export default Checkbox;

import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ id, name, checked, onChange, label, className }) => {
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        className={`form-checkbox h-5 w-5 text-blue-600 ${className}`}
      />
      {label && (
        <label htmlFor={id} className="ml-2 text-gray-700">
          {label}
        </label>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  label: '',
  className: '',
};

export default Checkbox;
