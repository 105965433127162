import { useCreateCloudClinicApiMutation, useDeleteCloudClinicApiMutation, useEditCloudClinicApiMutation,  } from "./example";
import { toast } from "react-toastify";
import { useEffect } from "react";

export const usePostCreateApiHook = () => {
  const [postApi] = useCreateCloudClinicApiMutation();
  const position = "top-right";

  const post = async (path, datas) => {
    try {
      const response = await postApi({ path: path, datas: datas });
      if (response.data.success) {
        if (response.success.data.successs) {
          const success = response.success.data.success;
          const successKey = Object.keys(success)[0];

          console.log(success[successKey].description);

        } 
      } else if (!response.data.success) {
        console.log("response:", response.error);
      } 

      return response;
    } catch (err) {
      console.log(err);
    }
  };
  return { post };
};

export const useEditCloudClinicApiHook = () => {
  const [editApi] = useEditCloudClinicApiMutation();
  const position = "top-right";

  const edit = async (path, data) => {
    try {
      const response = await editApi({ path, data });
      if (response.data.success) {
        toast.success("Edit successful", { position });
      } else {
        toast.error("Edit failed", { position });
      }
      return response;
    } catch (err) {
      toast.error("An error occurred", { position });
    }
  };
  return { edit };
};

export const useDeleteCloudClinicApiHook = () => {
  const [deleteApi] = useDeleteCloudClinicApiMutation();
  const position = "top-right";

  const deleteItem = async (path) => {
    try {
      const response = await deleteApi({ path });
      if (response.data.success) {
        toast.success("Delete successful", { position });
      } else {
        toast.error("Delete failed", { position });
      }
      return response;
    } catch (err) {
      toast.error("An error occurred", { position });
    }
  };
  return { deleteItem };
};