import { useState } from "react";
import { ReactComponent as WalletIcon } from "../../../assets/svgs/wallet-white.svg";
import { ReactComponent as WhiteLogo } from "../../../assets/svgs/logomark-white.svg";
import { addCommas } from "../../../components/TarrifBreakdown/data";
import {
  availableBalance,
  bookBalance,
  currentDate,
  hideBalance,
  transactions,
} from "./data";
import Button from "../../../components/Button";
import { DownArrow } from "../../../components/AddFacility/AddFacility.styled";
import { ReactComponent as BankIcon } from "../../../assets/svgs/bank.svg";
import { ReactComponent as FilterIcon } from "../../../assets/svgs/filter.svg";
import TransactionCard from "./TransactionCard";
import TransactionsFilterModal from "../../../components/modals/TransactionsFilterModal";

const Wallet = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className="w-full flex flex-col gap-4">
      <h4 className="font-bold text-deepBlue">Wallet</h4>
      <div className="bg-secondaryLight flex flex-col py-12 px-5 rounded-lg gap-6">
        <div className="flex flex-col bg-primary rounded-lg p-8 gap-6">
          <div className="flex items-center w-full justify-between">
            <div className="flex flex-col gap-1">
              <span className="text-secondaryLight flex items-center gap-1 text-base font-medium">
                <WalletIcon />
                Available Balance
              </span>
              <span className="text-secondaryLight font-bold text-2xl">
                NGN
                {hideBalance({
                  balance: addCommas(availableBalance),
                  hide: isChecked,
                })}
              </span>
              <span className="font-bold text-xs text-secondaryLight">
                Book balance: NGN
                {hideBalance({
                  balance: addCommas(bookBalance),
                  hide: isChecked,
                })}
              </span>
            </div>
            <WhiteLogo className="w-[46px] h-[38.28px]" />
          </div>
          <div className="flex items-center justify-between">
            <Button
              btnText={
                <span className="flex items-center gap-2.5">
                  <DownArrow />
                  Withdraw
                </span>
              }
              className="bg-secondaryLight px-2.5 py-2 text-base font-normal text-primary rounded-lg"
            />
            <div className="flex items-center gap-4">
              <span className="text-secondaryLight font-medium text-[15.29px]">
                {isChecked ? "Show " : "Hide "}balance
              </span>
              <label
                className={`toggle-switch relative flex items-center w-12 h-6 border-2 border-secondaryLight rounded-s-full rounded-e-full px-1 cursor-pointer`}
              >
                <input
                  type="checkbox"
                  id="toggle"
                  className="hidden"
                  checked={isChecked}
                  onChange={handleToggle}
                />
                <span
                  className={`bg-secondaryLight rounded-full w-4 h-4 ${
                    isChecked ? "ml-auto" : "mr-auto"
                  }`}
                ></span>
              </label>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between px-2 py-2.5 bg-greyBody rounded-[4px]">
          <span className="flex items-center gap-2 font-medium text-xs text-black">
            <BankIcon className="w-3.5 h-3.5" />
            Withdrawal Account
          </span>
          <DownArrow $pointRight className="text-primary" />
        </div>
        <div className="flex items-center justify-between">
          <span className="bold text-deepBlue text-base">Transactions</span>
          <span
            className="flex items-center gap-2 border border-primary rounded-[4px] px-2 font-medium text-xs text-primary cursor-pointer"
            onClick={() => setOpenFilter(true)}
          >
            <FilterIcon />
            Filter
          </span>
        </div>
        <div className="flex flex-col gap-6">
          <span className="text-blackText font-medium text-sm">
            {currentDate}
          </span>
          <div className="flex flex-col gap-6">
            {transactions.map(
              ({ orderNo, sender, amount, time, refCode }, index) => (
                <TransactionCard
                  key={index}
                  orderNo={orderNo}
                  sender={sender}
                  amount={amount}
                  time={time}
                  refCode={refCode}
                />
              )
            )}
          </div>
        </div>
      </div>
      <TransactionsFilterModal
        isOpen={openFilter}
        closeModal={() => setOpenFilter(false)}
      />
    </div>
  );
};

export default Wallet;
