import React, { useState, useEffect, useContext } from "react";
import { OrdersContext } from "./context";
import { orderStatus, returnTableData, returnTableHeaders, tabs } from "./data";
import { LuRefreshCcw } from "react-icons/lu";
import Input from "../../../components/Input";
import { SearchIcon } from "../../../components/TarrifBreakdown/TarrifBreakdown.styled";
import Table from "../../../components/Table";
import { PriceListTableWrapper } from "../Home/Home.styled";
import InvoiceDetails from "./InvoiceDetails";
import OrderDetails from "./OrderDetails";
import InvoicePreview from "./InvoicePreview";
import axiosInstance from "../../../api/constants";
import EmptyContent from "../../../components/EmptyContent";
import Empty from "../../../assets/empty-scroll.png";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Orders = () => {
  const { values, setValues } = useContext(OrdersContext);
  const {
    currentView,
    currentStatus,
    currentOrderIndex,
    currentInvoiceIndex,
    createInvoice,
    selectedInvoiceDetails,
  } = values;

  const [orders, setOrders] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [loadingInvoices, setLoadingInvoices] = useState(true);
  const [errorOrders, setErrorOrders] = useState(false);
  const [errorInvoices, setErrorInvoices] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);

  const labId = useSelector((state) => state?.user?.user?.id);
  const facilityType = useSelector((state) => state?.user?.user?.facilityType);

  const fetchOrders = async () => {
    setLoadingOrders(true);
    setErrorOrders(false);
    try {
      const pendingResponse = await axiosInstance.get(
        `/report/api/Orders/FacilityOrders?facilityId=${labId}&facilityType=${facilityType}&status=0`
      );
      const deliveredResponse = await axiosInstance.get(
        `/report/api/Orders/FacilityOrders?facilityId=${labId}&facilityType=${facilityType}&status=2`
      );

      setPendingOrders(
        Array.isArray(pendingResponse.data.data)
          ? pendingResponse.data.data
          : []
      );
      setDeliveredOrders(
        Array.isArray(deliveredResponse.data.data)
          ? deliveredResponse.data.data
          : []
      );
      setLoadingOrders(false);
    } catch (error) {
      setErrorOrders(true);
      setLoadingOrders(false);
    }
  };

  const fetchInvoices = async () => {
    setLoadingInvoices(true);
    setErrorInvoices(false);
    try {
      const response = await axiosInstance.get(
        `/report/api/Facility/FacilityPurchaseRequests?facilityId=${labId}`
      );

      setInvoices(Array.isArray(response.data.data) ? response.data.data : []);
      setLoadingInvoices(false);
    } catch (error) {
      setErrorInvoices(true);
      setLoadingInvoices(false);
    }
  };

  const fetchInvoiceDetails = async (Id) => {

    try {
      const response = await axiosInstance.get(
        `/lab/api/Lab/GetPurchaseRequest?Id=${Id}`
      );
      setValues((prevValues) => ({
        ...prevValues,
        selectedInvoiceDetails: response.data.data,
      }));
      toast.success("Invoice details fetched successfully");
    } catch (error) {
      console.error("Error fetching invoice details:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
    fetchInvoices();
  }, [labId, facilityType]);

  const handleRefresh = async () => {
    setRefreshing(true);
    await Promise.all([fetchOrders(), fetchInvoices()]);
    setRefreshing(false);
  };

  const handleClickTab = (tab) => {
    setValues((prevValues) => ({ ...prevValues, currentView: tab }));
  };

  const handleClickStatus = (status) => {
    setValues((prevValues) => ({ ...prevValues, currentStatus: status }));
  };

  const handleClickView = (index) => {
    if (currentView === "Orders") {
      setValues((prevValues) => ({ ...prevValues, currentOrderIndex: index }));
    } else if (currentView === "Invoice") {
      setValues((prevValues) => ({
        ...prevValues,
        currentInvoiceIndex: index,
      }));
      const invoiceId = invoices[index].id;
      fetchInvoiceDetails(invoiceId);
    }
  };

  const currentOrders =
    currentStatus === "Pending" ? pendingOrders[0] : deliveredOrders[0];

  return (
    <>
      <div className="flex flex-col w-full gap-14">
        {currentInvoiceIndex < 0 && currentOrderIndex < 0 && !createInvoice && (
          <>
            <div className="flex items-center justify-between w-full">
              {tabs.map((tab) => (
                <span
                  key={tab}
                  onClick={() => handleClickTab(tab)}
                  className={`w-1/2 text-center border-b-2 ${
                    tab === currentView
                      ? "border-b-primary cursor-not-allowed"
                      : "border-b-greyBorder cursor-pointer"
                  }`}
                >
                  {tab}
                </span>
              ))}
            </div>
            <div className="flex items-center w-full justify-between">
              <form className="relative w-1/2">
                <Input
                  type="search"
                  placeholder="Search for patient name or invoice"
                />
                <SearchIcon $middle color="#444448" />
              </form>
              <LuRefreshCcw
                className={`w-6 h-6 text-primary cursor-pointer ${
                  refreshing ? "animate-spin" : ""
                }`}
                onClick={handleRefresh}
              />
            </div>
          </>
        )}

        {currentView === "Orders" ? (
          currentOrderIndex > -1 ? (
            <OrderDetails currentOrder={currentOrders[currentOrderIndex]} />
          ) : (
            <>
              <div className="flex items-center mx-auto rounded-lg bg-secondaryLight p-2">
                {orderStatus.map((status) => (
                  <span
                    key={status}
                    className={`px-10 py-1 font-medium text-base rounded-[4px] ${
                      currentStatus === status
                        ? "bg-primary bg-opacity-60 cursor-not-allowed text-indigo-800"
                        : "cursor-pointer text-gray-900 text-opacity-70"
                    }`}
                    onClick={() => handleClickStatus(status)}
                  >
                    {status}
                  </span>
                ))}
              </div>
              <PriceListTableWrapper>
                <Table
                  headers={returnTableHeaders({ currentView })}
                  data={returnTableData({
                    onClickView: handleClickView,
                    currentView,
                    arr: currentOrders,
                  })}
                  onClick={handleClickView}
                />
                {currentOrders.length === 0 && (
                  <EmptyContent
                    image={Empty}
                    caption="No Order Here"
                    message="You have not gotten any orders yet"
                  />
                )}
              </PriceListTableWrapper>
            </>
          )
        ) : createInvoice ? (
          <InvoicePreview />
        ) : currentInvoiceIndex >= 0 && selectedInvoiceDetails ? (
          <InvoiceDetails invoice={selectedInvoiceDetails} />
        ) : (
          <PriceListTableWrapper>
            <Table
              headers={returnTableHeaders({ currentView })}
              data={returnTableData({
                onClickView: handleClickView,
                currentView,
                arr: invoices,
              })}
              onClick={handleClickView}
            />
            {invoices.length === 0 && (
              <EmptyContent
                image={Empty}
                caption="No Invoice Here"
                message="You have not gotten an invoice request"
              />
            )}
          </PriceListTableWrapper>
        )}
      </div>
    </>
  );
};

export default Orders;
