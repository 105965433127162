import React, { useState } from "react";
import Input from "../Input";
import Button from "../Button";

const EditModal = ({
  title,
  formData,
  onChange,
  btnText,
  btnClass,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await onSubmit(event); // Ensure onSubmit handles the form submission logic and is an async function
    setLoading(false);
  };

  return (
    <div className="w-[40vw] flex flex-col gap-6">
      <span className="text-deepBlue font-bold text-xl">{title}</span>
      <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
        {formData.map((data) => (
          <Input
            key={data.label}
            type={data.type}
            label={data.label}
            onChange={onChange}
            name={data.name}
            value={data.value}
            disabled={
              data.label === "Investigation Name *" || data.label === "Category"
            }
          />
        ))}
        <Button
          btnText={loading ? "Submitting..." : "Submit"}
          className={
            btnClass ||
            "bg-primary text-secondaryLight w-full py-4 px-0 rounded-xl"
          }
          disabled={loading} // Disable the button when loading
        />
      </form>
    </div>
  );
};

export default EditModal;
