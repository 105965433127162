import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { BackBtn } from "./Home.styled";

const EmailAddressChange = ({ onClickBackBtn }) => {
  return (
    <div>
      <span className="flex items-center gap-4">
        <BackBtn
          className="text-primary w-6 h-6 cursor-pointer"
          onClick={onClickBackBtn}
        />
        Email Address Change
      </span>
      <div className="bg-secondaryLight flex flex-col px-7 py-12 gap-20">
        <div className="flex flex-col gap-4">
          <span className="font-semibold text-2xl text-blackText">
            Enter email Address
          </span>
          <span className="text-base font-medium text-grey">
            We will send an OTP to verify the email address
          </span>
        </div>
        <form className="flex flex-col gap-20">
          <div className="border-b-2 border-b-primary">
            <Input type="email" label="Email Address" />
          </div>
          <Button
            btnText="Validate"
            className="ml-auto rounded-button bg-primary w-1/4 aspect-[129/28] text-secondaryLight text-base font-medium"
          />
        </form>
      </div>
    </div>
  );
};

export default EmailAddressChange;
