import React from "react";
import { ReactComponent as IncomingIcon } from "../../../assets/svgs/arrow-incoming.svg";
import { ReactComponent as OutgoingIcon } from "../../../assets/svgs/arrow-outgoing.svg";

const TransactionCard = ({ orderNo, sender, refCode, amount, time }) => {
  return (
    <div className="flex items-center justify-between border-b-[0.4px] border-greyBorder py-1">
      <div className="flex items-center gap-3">
        <div
          className={`flex w-10 h-10 items-center justify-center rounded-xl ${
            orderNo ? "bg-[#2CBCEF1A]" : "bg-[#E14A4A1A]"
          }`}
        >
          {orderNo ? <IncomingIcon /> : <OutgoingIcon />}
        </div>
        <div className="flex flex-col">
          <span className="font-semibold text-blackText text-xs">
            {orderNo ? `Order#: ${orderNo}` : "Withdrawal"}
          </span>
          <span className="font-normal text-xs text-gray-500">
            {sender || `Ref:${refCode}`}
          </span>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <span
          className={`font-semibold text-sm ${
            orderNo ? "text-green-500" : "text-red-500"
          }`}
        >{`${orderNo ? "+" : "-"}${amount}`}</span>
        <span className="font-normal text-xs text-gray-500">{time}</span>
      </div>
    </div>
  );
};

export default TransactionCard;
