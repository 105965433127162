import React from "react";
import Input from "../Input";
import Button from "../Button";
import Modal from "./Modal";

const InvoiceEditModal = ({isOpen, closeModal,  formData,
    onChange,
    btnText,
    btnClass,
    onSubmit,
   }) => {
  return (
    <div>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <div className="w-[40vw] flex flex-col gap-6">
          <h1 className="text-deepBlue font-bold text-xl">Edit Invoice</h1>
          <form className="flex flex-col gap-6" onSubmit={onSubmit}>
            {formData?.map((data) => (
              <Input
                key={data.label}
                type={data.type}
                label={data.label}
                onChange={onChange}
                name={data.name}
                value={data.value}
                // disabled={
                //   data.label === "Investigation Name *" ||
                //   data.label === "Category"
                // }
              />
            ))}
            <Button
              btnText={btnText || "Submit"}
              className={
                btnClass ||
                "bg-primary text-secondaryLight w-full py-4 px-0 rounded-xl"
              }
            />
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default InvoiceEditModal;
