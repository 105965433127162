import React from "react";
import Modal from "./Modal";
import Input from "../Input";
import Button from "../Button";

const data = [
  { label: "Start Date", placeHolder: "Select Date" },
  { label: "End Date", placeHolder: "Select Date" },
];
const TransactionsFilterModal = ({ isOpen, closeModal }) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal} className="w-1/3">
      <div className="flex flex-col gap-6">
        <span className="text-indigo-900 font-semibold text-base">Filter</span>
        <div className="flex flex-col gap-4">
          {data.map((item) => (
            <Input
              type="text"
              placeholder={item.placeHolder}
              label={item.label}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
            />
          ))}
        </div>
        <Button
          btnText="Proceed"
          className="w-full bg-primary py-4 rounded-xl font-bold text-sm text-secondaryLight"
        />
      </div>
    </Modal>
  );
};

export default TransactionsFilterModal;
