import Button from "../../../components/Button";

export const ratingsArr = [1, 2, 3, 4, 5];

export const ratingsDetails = [
  {
    title: "Overall",
    average: 4,
    "1 Star": 100,
    "2 Stars": 0,
    "3 Stars": 500,
    "4 Stars": 500,
    "5 Stars": 1000,
  },
  {
    title: "Timely Service",
    average: 4,
    "1 Star": 100,
    "2 Stars": 0,
    "3 Stars": 500,
    "4 Stars": 500,
    "5 Stars": 1000,
  },
  {
    title: "Customer Service",
    average: 4,
    "1 Star": 100,
    "2 Stars": 0,
    "3 Stars": 500,
    "4 Stars": 500,
    "5 Stars": 1000,
  },
];

export const returnBarDetails = (barDetails) => {
  const obj = { stars: "", count: 0 };
  const stars = Object.keys(barDetails).slice(2).reverse();
  const counts = Object.values(barDetails).slice(2).reverse();
  return stars.map((star, index) => ({
    ...obj,
    stars: star,
    count: counts[index],
  }));
};

export const pharmpriceListTableHeaders = [
  "Medication Name",
  "Medication Mass",
  "Price",
  "Actions",
];

const btnTexts = ["Delete", "Edit"];



export const pharmreturnPriceListTableData = (clickAction) => {
  return [
    {
      id: 0,
      "Medication Name": "Infusion Set",
      "Medication Mass": "NHIS",
      Price: "₦ 200.00",
      Actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 0)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 1,
      "Medication Name": "Infusion Set",
      "Medication Mass": "NHIS",
      Price: "₦ 200.00",
      Actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 1)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 2,
      "Medication Name": "Infusion Set",
      "Medication Mass": "NHIS",
      Price: "₦ 200.00",
      Actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 2)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 3,
      "Medication Name": "Infusion Set",
      "Medication Mass": "NHIS",
      Price: "₦ 200.00",
      Actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 3)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 4,
      "Medication Name": "Infusion Set",
      "Medication Mass": "NHIS",
      Price: "₦ 200.00",
      Actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 4)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
  ];
};

export const editFormData = [
  {
    type: "text",
    label: "Medication Name *",
    name: "Medication Name",
    value: "",
  },
  { type: "text", label: "Medication Mass", name: "Medication Mass", value: "" },
  { type: "number", label: "Price *", name: "price", value: "" },
];

export const unresolvedTableHeaders = [
  "Medication Name",
  "Category",
  "Formulation",
  "Action",
];

export const returnUnresolvedTableData = (clickAction) => {
  const data = pharmreturnPriceListTableData(clickAction);
  
  return data.map((item) => ({
    id: item.id,
    "medication name": item["Medication Name"],
    category: item["Medication Mass"],
    formulation: "Infusion/Galenical",
    action: (
      <Button
        key={item.id}
        onClick={() => clickAction(item.id)}
        btnText="View"
        className="bg-primary rounded-lg text-secondaryLight py-3 px-8"
      />
    ),
  }));
};

export const unresolvedBtnTexts = ['Proceed', 'Can’t Find']

export const emailAddress = 'Jondoepharmacy@gmail.com'

export const conflictCount = 8;
