export const initialStates = {
  currentView: "Invoice",
  currentStatus: "Pending",
  currentOrderIndex: -1,
  currentInvoiceIndex: -1,
  addNewIndex: -1,
  createInvoice: false,
  invoiceCreated: false,
  selectedInvoiceDetails: null,
};
