import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";

export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

export const FormLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 0.5rem;
`;

export const LabelText = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: #6c6c6c;
`;

export const FormInput = styled.input`
  border: none;
  outline: none;
  font-size: 0.875rem;
  font-weight: 600;
  color: #444448;

  &:focus {
    outline: none;
  }
`;

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
`;

export const SelectedText = styled.span`
  font-size: 0.875rem;
  font-weight: ${({ $selected }) => ($selected ? 600 : "")};
  color: ${({ $selected }) => ($selected ? "" : "#6C6C6C")};
`;

export const DownArrow = styled(IoIosArrowDown)`
  transform: rotate(
    ${({ $showing, $pointRight, $pointLeft }) =>
        $showing ? 180 : $pointRight ? 270 : $pointLeft ? 90 : 0}deg
  );
`;

export const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 120%;
  background-color: #fff;
  width: 100%;
  border: 1px solid #e2e2e2;
`;

export const ListItem = styled.span`
  color: #444448;
  padding: 0.5rem 1rem;

  &:hover {
    color: #fff;
    background-color: #2cbcef;
  }
`;
