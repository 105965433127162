import { createContext, useState } from "react";
import { initialStates } from "./initialStates";

export const OrdersContext = createContext(null);

const PharmOrdersProvider = ({ children }) => {
  const [values, setValues] = useState(initialStates);
  return (
    <OrdersContext.Provider value={{ values, setValues }}>
      {children}
    </OrdersContext.Provider>
  );
};

export default PharmOrdersProvider;
