import React, { useRef, useState } from "react";
import Modal from "./Modal";
import Clickable from "../../assets/clickable.png";
import Button from "../Button";
import Trash from "../../assets/svgs/trash.svg";
const ResultUploadModal = ({ isOpen, closeModal }) => {
    const fileInputRef = useRef(null);
      const [selectedFile, setSelectedFile] = useState(null);


  const handleFileChange = (event) => {
    const file = event.target.files[0];
      setSelectedFile(file);
     
    };
    
    const handleRemoveFile = () => {
      setSelectedFile(null);
    };

 
  return (
    <div>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <div className="w-[40vw] flex flex-col gap-2  mt-6">
          <h1 className="text-primary font-bold text-[32px] text-center">
            Investigation Result
          </h1>
          <p className="text-[#ABABAB] font-medium text-base text-center">
            Kindly upload your medical investigation
          </p>
          <label htmlFor="fileInput" className="cursor-pointer">
            <div className="bg-[#D9D9D93D]" onClick={ document.getElementById("fileInput")?.click()}>
              <div className="flex justify-center">
                <img src={Clickable} alt="" />
              </div>
            </div>
            <input
              type="file"
              id="fileInput"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </label>
          <div className="border-2 flex justify-center gap- p-2 text-center">
            {selectedFile ? (
              <>
                {selectedFile.name}{" "}
                <span onClick={handleRemoveFile}>
                  <img src={Trash} alt="" />
                </span>
              </>
            ) : (
              "-- No file selected --"
            )}
          </div>
         
          <Button
            btnText="Proceed"
            className="bg-primary text-secondaryLight w-full py-4 px-0 rounded-xl"
          />
        </div>
      </Modal>
    </div>
  );
};

export default ResultUploadModal;
