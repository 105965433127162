import React from 'react'
import SideBar from '../../components/SideBar';
import { Outlet } from 'react-router-dom';
import NavBar from '../../components/NavBar';

const HospitalFlowLayout = () => {
  return (
    <div className="flex flex-col h-screen overflow-auto">
      <NavBar showDetails />
      <div className="flex mt-20" style={{ height: "calc(100vh - 5rem)" }}>
        <SideBar showSpecialLinks={true} />
        <div className="flex w-full mt-9 mx-9 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default HospitalFlowLayout;