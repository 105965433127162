import Input from "../../../components/Input";
import { SearchIcon } from "../../../components/TarrifBreakdown/TarrifBreakdown.styled";
import { BackBtn } from "./Home.styled";
import { ReactComponent as RedCloseIcon } from "../../../assets/svgs/RedCloseIcon.svg";
import { unresolvedBtnTexts } from "./data";
import Button from "../../../components/Button";
import { useEffect, useState } from "react";
import axiosInstance from "../../../api/constants";
import { usePostCreateApiHook } from "../../../api/hooks";

const ViewUnresolved = ({
  name,
  category,
  selectedInvestigation,
  onClickBtn,
  goBack,
  onSubmit,
  onClickClose,
  searchQuery,
  setSearchQuery,
  filteredInvestigations,
  handleSelectInvestigation
}) => {
  return (
    <div className="flex flex-col gap-4 items-start">
      <span className="flex items-center gap-4 font-bold text-deepBlue text-xl">
        <BackBtn className="text-primary cursor-pointer" onClick={goBack} />
        Resolve Investigation Price
      </span>
      <div className="rounded-lg bg-blueTint w-full aspect-[973/141] flex justify-between items-center px-5">
        <span className="font-medium text-base text-blackText">{name}</span>
        <span className="font-medium text-base text-blackText">{category}</span>
      </div>
      <form className="flex flex-col w-full pb-2 gap-5" onSubmit={onSubmit}>
        <div className="relative flex flex-col border-b-2 border-b-primary">
          <Input
            type="search"
            label="CCL Investigation name"
            value={searchQuery}
            onChange={setSearchQuery}
          />
          <SearchIcon $toRight color="#444448" />
          {filteredInvestigations?.length > 0 && (
            <ul className="absolute bg-white border border-gray-300 w-full mt-2 z-10 max-h-60 overflow-auto">
              {filteredInvestigations.map((inv) => (
                <li
                  key={inv.id}
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleSelectInvestigation(inv)}
                >
                  {inv.investigationName}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="w-full flex flex-col gap-5">
          <div className="w-full flex items-center justify-between">
            <span>Selected Drugs</span>
            <RedCloseIcon onClick={onClickClose} className="cursor-pointer" />
          </div>
          <div className="w-full p-5 flex flex-col gap-7 rounded-[0.625rem] border border-gray-200">
          {selectedInvestigation && (
                  <div className="w-full p-5 flex flex-col gap-7 rounded-[0.625rem] border border-gray-200">
                    <p>
                      Category: <span>{selectedInvestigation.category}</span>
                    </p>
                    <p>
                      Investigation Name: <span>{selectedInvestigation.investigationName}</span>
                    </p>
                  </div>
                )}
          </div>
          <div className="flex items-center gap-5 ml-auto w-1/2">
            {unresolvedBtnTexts.map((text, index) => (
              <Button
                type={text==="Submit" ? "submit" : "button"}
                btnText={text}
                onClick={() => onClickBtn(text)}
                className={`border-2 rounded-[1.25rem] py-4 w-1/2 border-primary text-base font-medium ${
                  index === 0 ? "text-white bg-primary" : "text-primary"
                }`}
              />
            ))}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ViewUnresolved;
