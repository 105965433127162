import styled from "styled-components";
import Input from "../../../components/Input";
import { MdInfoOutline } from "react-icons/md";
import { IoIosStar } from "react-icons/io";
import { MdArrowCircleLeft } from "react-icons/md";

export const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.5rem;
  padding: 0 1rem 1rem 0;
  height: fit-content;
`;
export const PageTitle = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
  color: #343673;
`;

export const HomeTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ConflictsDisplay = styled.div`
  width: 45.5%;
  padding: 0.75rem 0.5rem;
  gap: 0.5rem;
  background: #ffa5000d;
  border-left: 1px solid #ffa500;
  display: flex;
  align-items: center;
  cursor: ${({ $active }) => ($active ? "pointer" : "")};
`;

export const InfoText = styled.span.attrs(({ $conflictsCount }) => ({
  children: `You have ${$conflictsCount} conflicting items on your master list`,
}))`
  font-size: 1rem;
  font-weight: 500;
  color: #3a3a3a;
`;

export const SearchForm = styled.form`
  margin-left: auto;
  position: relative;
  width: 47.9%;

  svg {
    width: 1.1rem;
    height: 1.1rem;
    color: #000;
    right: 0.625rem;
    left: unset;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const SearchInput = styled(Input).attrs({
  type: "search",
  placeholder: "Enter medication name to search",
})`
  padding: 0.9375rem 0.625rem;
  font-size: 1rem;
  font-weight: 400;
`;

export const InfoIcon = styled(MdInfoOutline)`
  width: 1.5rem;
  height: 1.5rem;
  color: #ffa500;
  transform: rotate(180deg);
`;

export const RatingsCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 29.9%;
  gap: 1rem;
`;

export const CardTitle = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  color: #3a3a3a;
`;

export const StarsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.59rem;
`;

export const StarsAndAverage = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const RatingStar = styled(IoIosStar)`
  fill: #${({ $filled }) => ($filled ? "FFBF75" : "D9D9D9")};
`;

export const RatingsCardsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const Bars = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

export const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  /* gap: 0.5rem; */
`;

export const BarSpan = styled.span`
  width: ${({ $left }) => ($left ? 14.56 : 12.56)}%;
  /* border: solid red; */
  font-size: 0.875rem;
  font-weight: 400;
  white-space: nowrap;
`;

export const IndicatorWrapper = styled.div`
  width: 64.4%;
  height: 1.125rem;
  border: 1px solid #d9d9d9;
`;

export const IndicatorSpan = styled.div`
  width: ${({ $width }) => $width}%;
  background-color: #fab513;
  height: 100%;
`;

export const PriceListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const PriceListTableWrapper = styled.div`
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  thead {
    tr {
      background: ${({ $whiteHeader }) => $whiteHeader ? '#fff' : ''};
      th {
        padding: 1.25rem;
      }
    }
  }

  tbody {
    td {
      padding: 2.5rem 1.25rem;
    }

    tr {
      border-bottom: ${({ $bottomBorder }) => $bottomBorder ? '1px solid #F0F0F0' : 'none'};
    }
  }

  th,
  td {
    text-align: left;
  }

  tr:nth-child(even) {
    background: #fff;
  }
`;

export const BackBtn = styled(MdArrowCircleLeft)`
width: 1.5rem;
height: 1.5rem;
`;
