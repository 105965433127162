import Table from "../../../components/Table";
import { BackBtn, PageTitle, PriceListTableWrapper } from "./Home.styled";
import { ConflictsDisplay, InfoIcon, InfoText } from "./Home.styled";
import { LuRefreshCcw } from "react-icons/lu";
import { unresolvedTableHeaders } from "./data";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../../api/constants";
import Pagination from "../../../components/Pagination";
import Button from "../../../components/Button";

const UnresolvedPriceList = ({ goBack, clickAction }) => {
  const [unresolvedData, setUnresolvedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [conflictCount, setConflictCount] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState({ pageNumber: 1, pageSize: 10 });
  const labId = useSelector((state) => state?.user?.user?.id);

  const fetchData = async () => {
    setLoading(true);
    setIsError(false);
    try {
      const response = await axiosInstance.get(
        `lab/api/AdminReport/GetUnResolvedList/UnResolvedList?LabId=${labId}`
      );
      console.log(response);
      const { data, total, totalPages } = response.data.data;

      if (!Array.isArray(data)) {
        throw new Error("API response data is not an array");
      }

      const sortedData = response.data.data.data.sort((a, b) =>
        a.investigationName.localeCompare(b.investigationName)
      );

      setUnresolvedData(sortedData);
      setTotalItems(total);
      setTotalPages(totalPages);

      const conflictItems = data.filter(item => item.itemStatus === 0);
      setConflictCount(conflictItems.length);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching unresolved data:", error);
      setIsError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, labId, page.pageNumber, page.pageSize]);

  const incrementPageNumber = () => {
    setPage((prevPage) => ({
      ...prevPage,
      pageNumber: prevPage.pageNumber + 1,
    }));
  };

  const decrementPageNumber = () => {
    setPage((prevPage) => ({
      ...prevPage,
      pageNumber: prevPage.pageNumber - 1,
    }));
  };

  const handlePageNumber = (page) => {
    setPage((prevPage) => ({
      ...prevPage,
      pageNumber: page,
    }));
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <BackBtn
          onClick={goBack}
          className="cursor-pointer text-primary w-6 h-6"
        />
        <PageTitle className="font-bold">
          Unresolved Investigation Price List
        </PageTitle>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex w-full justify-between items-center">
          {conflictCount > 0 && (
            <ConflictsDisplay>
              <InfoIcon />
              <InfoText $conflictsCount={conflictCount} />
            </ConflictsDisplay>
          )}
          <LuRefreshCcw
                className={`w-6 h-6 text-primary cursor-pointer ${
                  refreshing ? "animate-spin" : ""
                }`}
            onClick={() => fetchData()}
          />
        </div>
        <PriceListTableWrapper>
          <Table
            headers={unresolvedTableHeaders}
            data={unresolvedData.map((item, index) => ({
              ...item,
              "investigation name": item.investigationName,
              action: (
                <Button btnText="view" className="border border-primary py-1 px-4 text-white rounded-lg bg-primary " onClick={() => clickAction(item)} />
              ),
            }))}
          />
          <Pagination
            incrementPageNumber={incrementPageNumber}
            decrementPageNumber={decrementPageNumber}
            handlePageNumber={handlePageNumber}
            loading={loading}
            isError={isError}
            pageNumber={page.pageNumber}
            totalPages={totalPages}
            total={totalItems}
          />
        </PriceListTableWrapper>
      </div>
    </div>
  );
};

export default UnresolvedPriceList;
