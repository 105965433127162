/* eslint-disable react/prop-types */

const EmptyContent = ({image, caption, message}) => {
  return (
    <div>
      <img src={image} alt="" className="mx-auto w-72 h-72 my-8 object-cover" />
      <div>
        <p className="text-2xl  font-medium text-black text-center mb-4">
          {caption}
        </p>
        <p className="text-center text-sm text-blackLight ">{message}</p>
      </div>
    </div>
  );
}

export default EmptyContent