export const tarrifTabs = [
  "Consultation",
  "Medication",
  "Investigation",
  "Treatment services",
];

export const returnHeaders = (currentTab) => {
  const tableHeaders = [
    "S/N",
    currentTab === "Treatment services"
      ? "Treatment service"
      : `${currentTab} name`,
    "Price",
  ];
  return tableHeaders;
};

export const medicationHeaders = ["S/N", "Medications Name", "Price"];

export function addCommas(number) {
  let numStr = String(number);
  let regex = /\B(?=(\d{3})+(?!\d))/g;
  return numStr.replace(regex, ",");
}

export const medicationsTableData = [
  { "medications name": "Emzor Paracetamol", price: 2000 },
  { "medications name": "M & B Paracetamol", price: 2000 },
  { "medications name": "Ampicillin", price: 2000 },
  { "medications name": "Emzor Paracetamol", price: 2000 },
  { "medications name": "Ampicillin", price: 2000 },
  { "medications name": "M & B Paracetamol", price: 2000 },
  { "medications name": "Emzor Paracetamol", price: 2000 },
  { "medications name": "Cough Syrup", price: 2000 },
  { "medications name": "Ampicillin", price: 2000 },
  { "medications name": "Emzor Paracetamol", price: 2000 },
];

export const returnTableData = (currentTab) => {
  const tabName =
    currentTab === "Treatment services"
      ? "Treatment service".toLowerCase()
      : `${currentTab.toLowerCase()} name`;
  return medicationsTableData.map((data) => ({
    ...data,
    [tabName]: data["medications name"],
  }));
};

export const restructureArr = (arr) => {
  return [...arr].map((item, index) => ({
    ...item,
    id: index,
    "s/n": index + 1,
    price: addCommas(item.price),
  }));
};

export const btnTexts = ["Accept", "Decline"];
