import React, { useRef, useState } from "react";
import { ReactComponent as DeleteIcon } from "../../../assets/svgs/trash.svg"; // Import your delete icon

const UploadPricelist = () => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  return (
    <div>
      <h2 className="text-deepBlue font-bold text-xl">Upload Price List</h2>
      <div
        className="flex flex-col justify-center items-center bg-[#FFf] w-[80vw] gap-6 mt-6 "
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <h1 className="text-primary font-semibold text-xl mt-8 ">
          Upload pricing document
        </h1>
        <div className="bg-[#2CBCEF0D] border border-primary rounded-lg gap-6 w-[50%] flex flex-col justify-center items-center">
          <div className="my-2 flex flex-col gap-6">
            <div className="text-[#EB5757] font-normal text-sm mt-4 text-center">
              <>A document is uploading</>
            </div>

            <div className="flex flex-col items-center">
              <div className="bg-secondaryLight border  border-primary rounded-lg  px-8  py-2  flex flex-col justify-center items-center">
                <label
                  htmlFor="fileInput"
                  className="text-primary font-semibold text-base"
                >
                  Select File
                </label>

                <input
                  type="file"
                  id="fileInput"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
              <p className="text-blackText font-normal text-sm mt-4 text-center">
                Or Drag and drop file here
              </p>
            </div>
          </div>
        </div>
        {selectedFile && (
          <div className="bg-[#FAFAFA] border border-grey w-[50%] my-2 flex  rounded-lg items-center justify-between p-4">
            <span className="text-blackText font-semibold text-base">
              {selectedFile.name}
            </span>
            <button onClick={handleRemoveFile}>
              <DeleteIcon />
            </button>
          </div>
        )}
        <div className="w-[50%] mt-4">
          <button className="bg-primary text-white px-4 py-2 rounded-lg w-full">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadPricelist;
