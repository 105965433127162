import {createSlice} from "@reduxjs/toolkit"
const initialState = {
    user: null,
    selectedFacility: {
      name: "",
      initials: "",
    },
  };
  
  const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      setUser: (state, action) => {
        state.user = action.payload;
      },
      setSelectedFacility: (state, action) => {
        state.selectedFacility = action.payload;
      },
    },
  });
  
export const { setUser, setSelectedFacility } = userSlice.actions;
export const userReducer = userSlice.reducer
