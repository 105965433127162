import { useContext } from "react";
import { OrdersContext } from "./context";
import { orderStatus, returnTableData, returnTableHeaders, tabs } from "./data";
import { LuRefreshCcw } from "react-icons/lu";
import Input from "../../../components/Input";
import { SearchIcon } from "../../../components/TarrifBreakdown/TarrifBreakdown.styled";
import Table from "../../../components/Table";
import { PriceListTableWrapper } from "../Home/Home.styled";
import InvoiceDetails from "./InvoiceDetails";
import OrderDetails from "./OrderDetails";
import InvoicePreview from "./InvoicePreview";

const Orders = () => {
  const { values, setValues } = useContext(OrdersContext);
  const {
    currentView,
    currentStatus,
    currentOrderIndex,
    currentInvoiceIndex,
    createInvoice,
  } = values;

  const handleClickTab = (tab) => {
    setValues((prevValues) => ({ ...prevValues, currentView: tab }));
  };

  const handleClickStatus = (status) => {
    setValues((prevValues) => ({ ...prevValues, currentStatus: status }));
  };

  const handleClickView = (index) => {
    if (currentView === "Orders") {
      setValues((prevValues) => ({ ...prevValues, currentOrderIndex: index }));
    } else if (currentView === "Invoice") {
      setValues((prevValues) => ({
        ...prevValues,
        currentInvoiceIndex: index,
      }));
    }
  };

  return (
    <>
      <div className="flex flex-col w-full gap-14">
        {currentInvoiceIndex < 0 && currentOrderIndex < 0 && !createInvoice && (
          <>
            <div className="flex items-center justify-between w-full">
              {tabs.map((tab) => (
                <span
                  key={tab}
                  onClick={() => handleClickTab(tab)}
                  className={`w-1/2 text-center border-b-2 ${
                    tab === currentView
                      ? "border-b-primary cursor-not-allowed"
                      : "border-b-greyBorder cursor-pointer"
                  }`}
                >
                  {tab}
                </span>
              ))}
            </div>
            <div className="flex items-center w-full justify-between">
              <form className="relative w-1/2">
                <Input
                  type="search"
                  placeholder="Search for patient name or invoice"
                />
                <SearchIcon $middle color="#444448" />
              </form>
              <LuRefreshCcw className="w-6 h-6 text-primary cursor-pointer" />
            </div>
          </>
        )}

        {currentView === "Orders" ? (
          currentOrderIndex >= 0 ? (
            <OrderDetails />
          ) : (
            <>
              <div className="flex items-center mx-auto rounded-lg bg-secondaryLight p-2">
                {orderStatus.map((status) => (
                  <span
                    key={status}
                    className={`px-10 py-1 font-medium text-base rounded-[4px] ${
                      currentStatus === status
                        ? "bg-primary bg-opacity-60 cursor-not-allowed text-indigo-800"
                        : "cursor-pointer text-gray-900 text-opacity-70"
                    }`}
                    onClick={() => handleClickStatus(status)}
                  >
                    {status}
                  </span>
                ))}
              </div>
              <PriceListTableWrapper>
                <Table
                  headers={returnTableHeaders({ currentView })}
                  data={returnTableData({
                    onClickView: handleClickView,
                    currentView,
                  })}
                  onClick={handleClickView}
                />
              </PriceListTableWrapper>
            </>
          )
        ) : createInvoice ? (
          <InvoicePreview />
        ) : currentInvoiceIndex >= 0 ? (
          <InvoiceDetails />
        ) : (
          <PriceListTableWrapper>
            <Table
              headers={returnTableHeaders({ currentView })}
              data={returnTableData({
                onClickView: handleClickView,
                currentView,
              })}
              onClick={handleClickView}
            />
          </PriceListTableWrapper>
        )}
      </div>
    </>
  );
};

export default Orders;
