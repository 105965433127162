import Button from "../../../components/Button";

export const ratingsArr = [1, 2, 3, 4, 5];

export const ratingsDetails = [
  {
    title: "Overall",
    average: 4,
    "1 Star": 100,
    "2 Stars": 0,
    "3 Stars": 500,
    "4 Stars": 500,
    "5 Stars": 1000,
  },
  {
    title: "Timely Service",
    average: 4,
    "1 Star": 100,
    "2 Stars": 0,
    "3 Stars": 500,
    "4 Stars": 500,
    "5 Stars": 1000,
  },
  {
    title: "Customer Service",
    average: 4,
    "1 Star": 100,
    "2 Stars": 0,
    "3 Stars": 500,
    "4 Stars": 500,
    "5 Stars": 1000,
  },
];

export const returnBarDetails = (barDetails) => {
  const obj = { stars: "", count: 0 };
  const stars = Object.keys(barDetails).slice(2).reverse();
  const counts = Object.values(barDetails).slice(2).reverse();
  return stars.map((star, index) => ({
    ...obj,
    stars: star,
    count: counts[index],
  }));
};

export const priceListTableHeaders = [
  "Investigation Name",
  "Category",
  "Price",
  "Actions",
];

const btnTexts = ["Delete", "Edit"];

export const returnPriceListTableData = (clickAction) => {
  return [
    {
      id: 0,
      investigationName: "Hepatitis B",
      category: "Micro Biology Investigation",
      price: "₦ 200.00",
      actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 0)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 1,
      "investigation name": "Hepatitis B",
      category: "Micro Biology Investigation",
      price: "₦ 200.00",
      actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 1)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 2,
      "investigation name": "Hepatitis B",
      category: "Micro Biology Investigation",
      price: "₦ 200.00",
      actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 2)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 3,
      "investigation name": "Hepatitis B",
      category: "Micro Biology Investigation",
      price: "₦ 200.00",
      actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 3)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: 4,
      "investigation name": "Hepatitis B",
      category: "Micro Biology Investigation",
      price: "₦ 200.00",
      actions: (
        <div className="flex items-center gap-8">
          {btnTexts.map((text) => (
            <span
              key={text}
              className={`${text === "Delete" ? "text-red-500" : "text-primary"
                } text-base font-semibold cursor-pointer`}
              onClick={() => clickAction(text, 4)}
            >
              {text}
            </span>
          ))}
        </div>
      ),
    },
  ];
};

export const editFormData = [
  {
    type: "text",
    label: "investigation Name *",
    name: "investigationName",
    value: "",
  },
  { type: "text", label: "Category", name: "category", value: "" },
  { type: "number", label: "Price *", name: "price", value: "" },
];

export const unresolvedTableHeaders = [
  "Investigation Name",
  "Category",
  "Action",
];

export const returnUnresolvedTableData = (clickAction) => {
  const obj = { id: 0, "investigation name": "", category: "", action: "" };
  return returnPriceListTableData().map((item) => ({
    ...obj,
    "investigation name": item["investigation name"],
    id: item.id,
    category: item.category,
    action: <Button key={item.id} onClick={() => clickAction(item.id)} btnText='View' className='bg-primary rounded-lg text-secondaryLight py-3 px-8' />
  }));
};

export const unresolvedBtnTexts = ['Submit', 'Can’t Find']

export const emailAddress = 'Jondoepharmacy@gmail.com'

export const conflictCount = 8;
